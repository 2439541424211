import '../../Admin/style.css';
import React, { useContext, useEffect, useState } from 'react';
import UserApiClient from '../../ApiClients';
import { UserContext } from '../../TabContextProvider';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function Payment() {

    const { User } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        Swal.fire('Subscription failed to updated.', '', 'error')
        setTimeout(()=>{
            navigate('/settings?tab=2')
        }, 2000)
    }, [])

    return (
        <>
            <div className='backarrow'></div>
            <div className="containerpay mt-5">
                <div className="row m-0">
                    <div className="col-sm-12 bg-purple text-white p-5">
                        <div className='d-flex align-items-center gap-2 mb-4'>
                            <i className="fa-solid fa-circle-arrow-left" onClick={() => navigate('/settings?tab=2')} style={{ fontSize: '24px', cursor: "pointer" }}></i>
                            <img src="/assets/Logo3.png" className="logo" onClick={() => navigate('/settings?tab=2')} alt="logo" style={{ width: "10%", cursor: "pointer" }} />
                        </div>
                        <h4>Subscribed Failed</h4>
                    </div>

                </div>
            </div>
        </>
    );
}
export default Payment;