import '../../Admin/style.css';
import React from 'react';

function Home() {

    return (
        <>
            <div className="main-sec mt-5">
                <div className='row'>
                    <div className='col-sm-6 col-md-4 col-lg-3 col-xl-3'>
                        <div className='adContainer'>
                            <h3>Total Images</h3>
                            <h2><strong>16085</strong></h2>
                            <div className='mt-5'>
                                <span style={{color: "Green", marginRight: "15px"}}>+32.40% </span>
                                <span> Increased last month</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3 col-xl-3'>
                        <div className='adContainer'>

                        </div>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3 col-xl-3'>
                        <div className='adContainer'>

                        </div>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3 col-xl-3'>
                        <div className='adContainer'>

                        </div>
                    </div>
                </div>

            </div>


        </>
    );
}

export default Home;
