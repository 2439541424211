import '../../Admin/style.css';
import React, { useState } from 'react';
import Navbar from '../Sidebars/Navbar.jsx';
import Header from '../Sidebars/header.jsx';
import { useNavigate } from 'react-router-dom';

function Blog() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState(null);

    const openModal = (blog) => {
        setSelectedBlog(blog);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedBlog(null);
    };

    const handleDelete = () => {
        // Implement deletion logic here
        console.log("Deleted blog:", selectedBlog);
        closeModal();
    };

    const navigate = useNavigate();
    const createblog = () => {
        navigate('/create-blog');
      };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-2 p-0" style={{ borderRight: "1px solid #ebebeb" }}>
                        <Navbar />
                    </div>
                    <div className="col-10 p-0">
                        <Header />
                        <div className="main-sec">
                            <div className='serchblog' >
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <h5 className='m-0' style={{ width: "50%" }}>Blog List</h5>
                                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"></input></div>
                                <button onClick={createblog} className='btn btn-primary px-4 py-2'>Create Blog</button>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-3'>
                                    <div className='adContainer'>
                                        <div className='bloglist'>
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider round"></span>
                                            </label>
                                            <div>
                                                <button><span>Edit<i className="fa-solid fa-pen-to-square ml-2"></i></span></button>
                                                <button onClick={() => openModal('BlogID123')}><i className="fa-solid fa-trash-can mx-2"></i></button>
                                            </div>
                                        </div>
                                        <div className='blogimg'>
                                            <img src="/assets/Logo1.png" className="logo p-1" alt="logo" style={{ width: "100%" }} />
                                        </div>
                                        <div>
                                            <p>How to Play with Crypto currency<br/>
                                            <small>create at 22/05/2024</small>
                                            </p>
                                            <small>Learn how to Play with Crypto currency and purchase a plan on market</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deletion</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this blog?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={handleDelete}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Blog;
