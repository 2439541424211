import '../../Admin/style.css';
import React from 'react';
import Navbar from '../Sidebars/Navbar.jsx';
import Header from '../Sidebars/header.jsx';
import { useNavigate } from 'react-router-dom';

function Categorylist() {

    const navigate = useNavigate();
    const backblogcateg = () => {
        navigate('/blog-category');
    };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-2 p-0" style={{ borderRight: "1px solid #ebebeb" }}>
                        <Navbar />
                    </div>
                    <div className="col-10 p-0">
                        <Header />
                        <div className="main-sec">
                            <div className='serchblog' >
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <h5 className='m-0'>Create Category - Category List</h5>
                                </div>
                                <button onClick={backblogcateg} className='btn btn-primary px-4 py-2'>Back to Category List</button>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-10'>
                                    <div className="form-group">
                                        <label htmlFor="usr"><strong>Category Title:</strong></label>
                                        <input type="text" className="form-control my-2" id="usr" />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="Blogdis"><strong>Category Description</strong></label>
                                        <input type="text" className="form-control my-2" id="Blogdis" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="roleSelect" className="form-label"><strong>Parent Categories</strong></label>
                                        <select id="roleSelect" className="form-select form-select-lg my-2" aria-label=".form-select-lg example">
                                            <option selected>Select categories</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Metadis"><strong>Category Image:</strong></label>
                                        <input type="text" className="form-control my-2" id="Metadis" />
                                    </div>
                                    <div>
                                        <button type="submit" className="btn btn-primary signin-btn py-3 mt-4" onClick={''}>Add  Category</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Categorylist;
