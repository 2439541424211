import React, { useState } from 'react';
import OpenHeader from './OpenHeader';
import Footer from './footer';


const Guide = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const blogPosts = [
        {
            title: "How to choose the right line an app development?",
            date: "April 18, 2024",
            category: "Creative",
            image: "assets/images/blog/home2/1.png",
            description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making...",
        },
        {
            title: "AI Content Creation Tools: 7 Tools to Supercharge Pro.",
            date: "June 18, 2024",
            category: "AI",
            image: "assets/images/blog/home2/2.png",
            description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
        },
        {
            title: "Innovative Developments in AI Chatbot Technologies",
            date: "April 18, 2024",
            category: "Animation",
            image: "assets/images/blog/home2/3.png",
            description: "Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur...",
        },
        {
            title: "How to choose the right line an app development?",
            date: "April 18, 2024",
            category: "Creative",
            image: "assets/images/blog/home2/4.png",
            description: "Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
        }
    ];

    return (
        <>
        <OpenHeader/>
        <section className="h2_blog-area pt-135 pb-140">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="blog_details-right mb-40">
                            <div className="h3_faq-area blog_details-widget mb-30 tp_fade_left">
                                <h5 className="blog_details-widget-title mb-30">Search</h5>
                                <form className="blog_details-widget-search">
                                    <input 
                                        type="text" 
                                        placeholder="Search here..." 
                                        value={searchTerm} 
                                        onChange={handleSearchChange} 
                                    />
                                    <button type="submit"><i className="fa-solid fa-magnifying-glass"></i></button>
                                </form>
                            </div>
                            <div className="h3_faq-area blog_details-widget mb-30 tp_fade_left">
                                <h5 className="blog_details-widget-title mb-25">Category</h5>
                                {/* <ul>
                                    <li><a href="blog-details.html#">AI Revolution <span>(4)</span></a></li>
                                    <li><a href="blog-details.html#">New AI <span>(2)</span></a></li>
                                    <li><a href="blog-details.html#">Content Writing <span>(3)</span></a></li>
                                    <li><a href="blog-details.html#">Writing <span>(4)</span></a></li>
                                    <li><a href="blog-details.html#">Image Generator <span>(2)</span></a></li>
                                </ul> */}
                            </div>
                            <div className="h3_faq-area blog_details-widget mb-30 tp_fade_left">
                                <h5 className="blog_details-widget-title mb-25">Recent Posts</h5>
                                {/* <ul>
                                    <li><a href="blog-details.html#">Balancing Innovation and Responsibility With AI.</a></li>
                                    <li><a href="blog-details.html#">Exploring Trends, Challenges, and Opportunities.</a></li>
                                    <li><a href="blog-details.html#">Shaping the Future of Work and Society.</a></li>
                                    <li><a href="blog-details.html#">The Evolution of Artificial Intelligence.</a></li>
                                    <li><a href="blog-details.html#">Balancing Innovation and Responsibility With AI.</a></li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-8">
                        <div className="row">
                            {blogPosts.map((post, index) => (
                                <div key={index} className="col-xl-12 col-md-6 tp_fade_left" data-fade-from="left" style={{ transitionDelay: `${index * 0.2}s` }}>
                                    <div className="h2_blog-item mb-35">
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="h2_blog-img w_img mb-25">
                                                    <a href="blog-details.html"><img src={post.image} alt="Image Not Found" /></a>
                                                </div>
                                            </div>
                                            <div className="col-7">
                                                <div className="h2_blog-content">
                                                    <h4 className="h2_blog-content-title mb-35">
                                                        <a href="blog-details.html">{post.title}</a>
                                                    </h4>
                                                    <p>{post.description}</p>
                                                    <div className="h2_blog-content-meta mt-4">
                                                        <span><a href="blog.html#">{post.category}</a></span>
                                                        <span><i className="fa-solid fa-calendar-days"></i>{post.date}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> */}
                </div>
                {/* <div className="row">
                    <div className="col-12">
                        <div className="pagination-area pt-30 d-flex justify-content-center tp_fade_bottom">
                            <span><i className="fa-solid fa-arrow-left"></i></span>
                            <ul>
                                <li><a href="blog.html#" className="active">01</a></li>
                                <li><a href="blog.html#">02</a></li>
                                <li><a href="blog.html#">03</a></li>
                            </ul>
                            <span><i className="fa-solid fa-arrow-right"></i></span>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
        <Footer/>
        </>
    );
};

export default Guide;
