import '../../Admin/style.css';
import React, { useState, useEffect } from 'react';
import Navbar from '../Sidebars/Navbar.jsx';
import Header from '../Sidebars/header.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import UserApiClient from '../../ApiClients/index.jsx';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

function NewLetter() {
    const [isMobCheck, setIsMobCheck] = useState();
    const [newsLetters, setNewsLetters] = useState([]);
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    let isFetching = false;

    const handleResize = () => {
        setIsNavbarVisible(window.innerWidth >= 768);
    };

    useEffect(() => {
        setIsMobCheck(window.innerWidth >= 768);
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleNavbar = () => {
        setIsNavbarVisible(prevState => !prevState);
    };

    const handleExport = () => {
        // Step 1: Format the data
        const formattedData = newsLetters.map(item => ({
            id: item.id,
            email: item.email,
            user: item.user?item.user.email:"Unknown",
            created_at: item.created_at,
            status: item.status?"Running":'Stopped',
        }));

        // Step 2: Create a new workbook and worksheet
        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Subscriptions');

        // Step 3: Export the workbook to an Excel file
        XLSX.writeFile(workbook, 'News-Letters-data-.xlsx');
    };

    const handleDelete = async (id) => {
        await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await UserApiClient.delete(`api/news-letter/${id}/`, {}, true).then(async res => {
                    if (res.success) {
                        await fetchData();
                        Swal.fire(res.data.message, '', 'success', 3000)
                    }
                })
            }
        });
    };

    async function handleSourceSearch(e) {
        e.preventDefault();
        const searchTerm = e.target.searchTerm.value;

        // Reset the documents based on the current value
        if (searchTerm.trim() === "") {
            // If searchTerm is empty, show original data
            await fetchData(false);
            return; // Exit the function early
        }

        // // Filter based on the searchTerm
        const filteredData = newsLetters.filter(item =>
            item.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setNewsLetters(filteredData);
    }
    

    // Your API call to fetch data
    const fetchData = async (useLoader) => {
        isFetching = true;
        try {
            const res = await UserApiClient.get(`api/news-letter/`, {}, true, undefined, useLoader);
            if (res.success) {
                setNewsLetters(res.data);
            }
            isFetching = false;
        } catch (error) {
            console.log("Error fetching user data:", error);
        }
    };


    useEffect(() => {
        if (!isFetching) {
            fetchData()
        }
    }, []);


    return (
        <>
            <div className="container-fluid p-0">
                <Header toggleNavbar={toggleNavbar} isNavbarVisible={isNavbarVisible} />
                <div className="row">
                    <div
                        className={`sizefix col-md-0 col-lg-3 col-xl-2 p-0 ${isNavbarVisible ? '' : 'd-none'}`}
                        style={{ borderRight: "1px solid #ebebeb" }}
                    >
                        <Navbar />
                    </div>
                    <div className={`${isMobCheck ? (isNavbarVisible ? 'col-10 p-0' : 'col-12 p-0') : (isNavbarVisible ? 'd-none' : 'col-12 p-0')}`}>

                        <div className="main-sec mt-5">
                            <div className='serchblog p-4'>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <h5 className="m-0 mb-2 mb-md-0" style={{ width: "50%" }}>News Letters</h5>
                                    <form className='searchbartab' onSubmit={handleSourceSearch}>
                                        <input type="text" placeholder="Search by email" name="searchTerm" className='m-0 rounded' style={{ width: "80%" }} />
                                        <button type="submit" className='border rounded'><i className="fa fa-search"></i></button>
                                    </form>
                                </div>
                                <div className='col-sm-8 col-md-7 col-lg-6 col-xl-6 selectbtns'>
                                    <button className='btn btn-primary px-4 py-2' onClick={handleExport}>Download</button>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="table-responsive">
                                        <table className="table category-tab text-start">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">User Email</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {newsLetters.map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td>#{item.id}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.user?item.user.email:"Unknown"}</td>
                                                        <td>{item.status?"Running":'Stopped'}</td>
                                                        <td>
                                                            <button className='icn-btn' onClick={() => handleDelete(item.id)}>
                                                                <i className="fa-solid fa-trash-can mx-2" aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default NewLetter;