import '../../Admin/style.css';
import React, { useState } from 'react';
import Navbar from '../Sidebars/Navbar.jsx';
import Header from '../Sidebars/header.jsx';
import { useNavigate } from 'react-router-dom';

function Blog() {
    const [selectedBlog, setSelectedBlog] = useState(null);

    const navigate = useNavigate();
    const createblog = () => {
        navigate('/create-blog');
    };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-2 p-0" style={{ borderRight: "1px solid #ebebeb" }}>
                        <Navbar />
                    </div>
                    <div className="col-10 p-0">
                        <Header />
                        <div className="main-sec">
                            <div className='serchblog' >
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <h5 className='m-0'>Create Blog - Blog List</h5>
                                </div>
                                <button onClick={createblog} className='btn btn-primary px-4 py-2'>Back to Blog List</button>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-12'>
                                    <div className='adContainer p-4'>
                                        <div className='bloglist'>
                                            <label className="switch">
                                                {/* <input type="checkbox" />
                                                <span className="slider round"></span> */}
                                            </label>
                                            <div>
                                                <button><span>Edit<i className="fa-solid fa-pen-to-square ml-2"></i></span></button>
                                                <button><i className="fa-solid fa-trash-can mx-2"></i></button>
                                            </div>
                                        </div>
                                        <h2>How to pay with Crypto-currency</h2>
                                        <h5>Learn how to pay with cryptocurrency and purchase a plan.</h5>
                                        <div className='blogimg my-5'>
                                            <img src="/assets/Logo1.png" className="logo p-1" alt="logo" style={{ width: "100%" }} />
                                        </div>
                                        <div>
                                        <h2>How to pay with Crypto-currency</h2>
                                        <h5>Learn how to pay with cryptocurrency and purchase a plan.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Blog;
