import { useContext, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import UserApiClient from "../ApiClients";
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import Swal from "sweetalert2";
import { UserContext } from "../TabContextProvider";

const TeamMemberAccept = () => {
    const navigate = useNavigate();
    const { User } = useContext(UserContext);
    let isFetching = false;

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const team = query.get('team');
    const token = query.get('token');
    const role = query.get('role');

    // Your API call to fetch user data
    const fetchData = async () => {
        isFetching = true;
        await UserApiClient.get(`api/member/accept/?team=${team}&role=${role}&token=${token}`, {}, false).then(async res => {
            if (!res.success) {
                setTimeout(() => { window.location.href = '/'; }, 2000);
            } else if (User.id){
                Swal.fire(res.data.message, '', 'success', 2000);
                setTimeout(()=>{window.location.href = '/dashboard/'}, 2000)
            } else {
                Swal.fire(res.data.message, '', 'success', 2000);
                setTimeout(()=>{window.location.href = '/'}, 2000)
            }
            isFetching = false;
        })
    }

    useEffect(() => {
        if (!!team && !!token && !!role && !isFetching) {
            fetchData();
        }
    }, [team, token, role, User])

    return (
        <div className="vh-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: '#f0f4f9' }}>
            <Row className="w-100">
                <Col md={8} className="mx-auto">
                    <Card className="p-5 shadow-sm" style={{ borderRadius: '28px' }}>
                        <Card.Body>
                            <Card.Title className="text-center mb-4">
                                <h4>Team Member Acceptance request</h4>
                            </Card.Title>

                            <Form className="text-center">
                                <h5 className="mb-3">Please wait...</h5>

                                <div className="mb-3">
                                    <div className="w-100 d-flex align-items-center justify-content-center">
                                        <Button variant="btn btn-primary" onClick={() => { navigate('/') }}>
                                            Home
                                        </Button>
                                    </div>
                                </div>

                                <div className="text-muted small">
                                    Before using this app, you can review ChatZippy <NavLink href="/privacy-policy">privacy policy</NavLink> and <NavLink href="/terms-of-service">Terms of Service</NavLink>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>

                    <div className="text-center mt-3 small">
                        <NavLink href="/">Help</NavLink> | <NavLink href="/privacy-policy">Privacy</NavLink> | <NavLink href="/terms-of-service">Terms</NavLink>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default TeamMemberAccept