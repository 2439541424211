// import '../App.css';
import '../NewApp.css';
import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../TabContextProvider';
import OpenHeader from './OpenHeader';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import UserApiClient from '../ApiClients';
import Swal from 'sweetalert2';
import Contact from '../Covers/contact';
import Footer from './footer';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Dashboard() {
    const token = localStorage.getItem('token');
    const [value, setValue] = useState(0);
    const { User, Team } = useContext(UserContext);
    const [planData, setPlanData] = useState({});
    const [isYearly, setIsYearly] = useState(false);
    const [activeIndex, setActiveIndex] = useState(1);
    const [email, setEmail] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
    const isAuth = !User.id || !token ? false : true
    let isFetching = false;

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const handleToggle = (index) => {
        setActiveIndex(index);
    }

    const navigate = useNavigate();

    const layoutPage = () => {
        if (User?.is_superuser) {
            navigate('/all-user');
        } else if (!User?.is_superuser) {
            navigate(`/dashboard/${Team?.slug || ""}`);
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchData() {
        isFetching = true
        await UserApiClient.get(`bot/plans/get/`, {}, false).then(async res => {
            if (res.success) {
                setPlanData(res.data);
                isFetching = false
            }
        })
    }

    async function handleNewsLetterSubscribe(e) {
        e.preventDefault();
        const email = e.target.email.value;
        let useToken = false;
        if (User.id && localStorage.getItem("token")) {
            useToken = true
        }
        await UserApiClient.post(`api/news-letter/`, { "email": email }, useToken).then(async res => {
            if (res.success) {
                Swal.fire(res.data.message, '', 'success', 3000);
                e.target.reset();
                useToken = false;
            }
        })
    }

    useEffect(() => {
        if (!isFetching) fetchData();
    }, [])

    const images = [
        { src: '/assets/chatbotnimi.png', alt: 'Accordion Item 1', width: "50%" },
        { src: '/assets/changes.png', alt: 'Accordion Item 2', width: "75%" },
        { src: '/assets/iframeprev.png', alt: 'Accordion Item 3', width: "75%" },
        { src: '/assets/nimitools.png', alt: 'Accordion Item 4', width: "75%" },
    ];
    const features = [
        {
            id: 1,
            title: "Generate text quickly",
            description: "Effortlessly generate text with precision and speed.",
            imgSrc: "assets/images/choose/1.png",
        },
        {
            id: 2,
            title: "Top-notch content",
            description: "Deliver content of exceptional quality.",
            imgSrc: "assets/images/choose/2.png",
        },
        {
            id: 3,
            title: "Supports over 40 languages",
            description: "Communicate effectively with your customers in their native language, even if your data is in another language.",
            imgSrc: "assets/images/choose/3.png",
        },
        {
            id: 4,
            title: "24/7 Support",
            description: "Get support around the clock, every day of the week.",
            imgSrc: "assets/images/choose/4.png",
        },
        {
            id: 5,
            title: "Multiple Data Sources",
            description: "Import data from various sources to train your chatbot.",
            imgSrc: "assets/images/choose/5.png",
        },
        {
            id: 6,
            title: "Customizations",
            description: "Customize your chatbot settings, appearance, and functionality to perfectly match your unique preferences and requirements.",
            imgSrc: "assets/images/choose/6.png",
        },
        {
            id: 7,
            title: "Integrations",
            description: "Link your chatbot with popular tools such as Slack, WhatsApp, Zapier, and others of your choice.",
            imgSrc: "assets/images/choose/7.png",
        },
        {
            id: 8,
            title: "Privacy & Security",
            description: "Use secure servers with strong encryption and strict access controls to host your data.",
            imgSrc: "assets/images/choose/8.png",
        },
        {
            id: 9,
            title: "Advanced AI Models",
            description: "Choose from a variety of AI models, including GPT-3.5-turbo and GPT-4.",
            imgSrc: "assets/images/choose/9.png",
        },
        {
            id: 10,
            title: "Auto-Retrain",
            description: "Enable your chatbot to update itself automatically and stay in sync with your latest information.",
            imgSrc: "assets/images/choose/10.png",
        },
    ];

    const featureQuestions = [
        {
            title: 'Import your data',
            description: 'Link your data sources, upload files, or provide websites for crawling. Chatbase uses this data to train your chatbot effectively.',
            imgSrc: 'assets/images/feature/s1.png'
        },
        {
            title: 'Personalize how it behaves and looks',
            description: 'Customize your chatbot\'s appearance with branded colors and logos, and define its behavior with personalized instructions that reflect your brand\'s personality.',
            imgSrc: 'assets/images/feature/s2.png'
        },
        {
            title: 'Integrate it into your website',
            description: 'Integrate a chat widget into any website using a simple code snippet.',
            imgSrc: 'assets/images/feature/s3.png'
        }
    ];

    const appImages = [
        '1.png', '2.png', '3.png', '4.png', 
        '5.png', '6.png', '7.png', '8.png'
    ];

    const shapeImages = [
        { src: 'shape-1.png', speed: 0.6 },
        { src: 'shape-2.png', speed: 0.8 },
        { src: 'shape-4.png', speed: 0.7 },
        { src: 'shape-5.png', speed: 0.7 },
        { src: 'shape-6.png', speed: 0.8 },
    ];

    const togglePlan = () => {
        setIsYearly(prev => !prev);
    };

    const subscribeToPlan = (plan) => {
        // Subscription logic here
        console.log(`Subscribed to ${plan}`);
    };

    const plans = [
        {
            title: 'Free Trial',
            monthly: {
                price: 'Free - $0',
                description: 'No Need For Credit Card',
                features: [
                    '40 message credits/month',
                    '1 chatbot',
                    '500,000 characters/chatbot',
                    '1 team member',
                    'Limit to 20 links to train on',
                    'Embed on unlimited websites',
                    'Capture leads',
                    'View chat history',
                    'GPT-4o (most advanced and efficient model)'
                ],
            },
            yearly: {
                price: 'Free - $0',
                description: 'No Need For Credit Card',
                features: [
                    '40 message credits/month',
                    '1 chatbot',
                    '500,000 characters/chatbot',
                    '1 team member',
                    'Limit to 20 links to train on',
                    'Embed on unlimited websites',
                    'Capture leads',
                    'View chat history',
                    'GPT-4o (most advanced and efficient model)'
                ],
            }
        },
        {
            title: 'Standard Plan',
            monthly: {
                price: '$49',
                description: '/Month (annually billed)',
                features: [
                    'Everything in Free, plus...',
                    '6000 message credits/month after that use own GPT Key',
                    '5 chatbots',
                    '20,000,000 characters/chatbot after that use own GPT Key',
                    'Unlimited links to training on chatzippy'
                ],
                planId: 'price_2'
            },
            yearly: {
                price: '$490',
                description: '/Year (annually billed)',
                features: [
                    'Everything in Free, plus...',
                    '6000 message credits/month after that use own GPT Key',
                    '5 chatbots',
                    '20,000,000 characters/chatbot after that use own GPT Key',
                    'Unlimited links to training on chatzippy'
                ],
                planId: 'price_3'
            },
            bestChoice: true
        },
        {
            title: 'Unlimited Plan',
            monthly: {
                price: '$299',
                description: '/Month (annually billed)',
                features: [
                    'Everything in Standard, plus...',
                    '40,000 message credits/month included (Messages over the limit will use your OpenAI API Key)',
                    'Unlimited chatbots',
                    '6 team members',
                    "Remove 'Powered by Chatzippy'"
                ],
                planId: 'price_4'
            },
            yearly: {
                price: '$2990',
                description: '/Year (annually billed)',
                features: [
                    'Everything in Standard, plus...',
                    '40,000 message credits/month included (Messages over the limit will use your OpenAI API Key)',
                    'Unlimited chatbots',
                    '6 team members',
                    "Remove 'Powered by Chatzippy'"
                ],
                planId: 'price_5'
            }
        },
        {
            title: 'Enterprise',
            monthly: {
                contactUs: true
            },
            yearly: {
                contactUs: true
            }
        }
    ];


        const brands = [
            '1.png', '2.png', '3.png', '4.png',
            '5.png', '6.png', '7.png', '8.png', '9.png'
        ];

    return (

        <div className="container-fluid p-0">
            <div className='dash-background'>
                {/* Header */}
                <OpenHeader />
                {/* Header */}

                <section className="banner-area">
                    <div className="container custom-container-1">
                        <div className="banner-single">
                            <div className="banner-content">
                                <span className="banner-content-subtitle tp_fade_left">Today special offer</span>
                                <h1 className="banner-content-title tp_has_text_reveal_anim">
                                    Personalized AI Chat Assistant for Your Website
                                </h1>
                               <div className="banner-content-btn">
                              <button
                                className="theme-btn tp_fade_bottom"
                                onClick={(e) => {
                                  if (!isAuth) {
                                    e.preventDefault(); // Prevent default action
                                    navigate('/signup'); // Navigate to the signup page if not authenticated
                                  } else {
                                    navigate('/dashboard'); // Navigate to the dashboard if authenticated
                                  }
                                }}
                              >
                                Get Started For Free
                              </button>
                              <span className="tp_fade_bottom">
                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M17.4555 0.0244961C16.2201 0.206689 15.2018 0.523653 14.0862 1.07023C12.6337 1.78153 11.3259 2.7424 10.0056 4.06517C8.07885 5.9969 6.49652 8.40034 5.42334 11.0359C5.33349 11.258 5.25612 11.4427 5.25113 11.4477C5.24863 11.4502 5.22118 11.3304 5.19123 11.1781C4.88425 9.64324 3.79359 8.26307 2.56067 7.84877C2.20128 7.72898 1.8369 7.70152 1.50745 7.77141C1.27784 7.81882 0.920944 8.00102 0.736256 8.16574C0.481686 8.38786 0.204654 8.80716 0.0524114 9.1965L0 9.32378L0.292007 9.34125C0.983338 9.37869 1.43258 9.58584 1.8943 10.0725C2.14388 10.3346 2.32357 10.6041 2.53322 11.0259C2.75534 11.4727 2.89511 11.8395 3.26448 12.9327C3.62887 14.0084 3.77362 14.3877 3.9658 14.7721C4.21288 15.2662 4.52485 15.683 4.80438 15.8952L4.94415 16L5.08391 15.9052C5.30853 15.7504 5.78273 15.2612 6.0348 14.9243C6.53645 14.2455 6.90084 13.6065 7.8717 11.6898C8.97733 9.50348 9.39912 8.71731 10.0231 7.68405C12.0621 4.30227 14.4106 1.90632 17.3157 0.24163C17.5279 0.121832 17.7076 0.0170088 17.7126 0.00952148C17.73 -0.00794792 17.6377 -0.000461578 17.4555 0.0244961Z"
                                    fill="currentColor"
                                  />
                                </svg>
                                No credit card Required
                              </span>
                            </div>
                            </div>
                            <div className="banner-img tp_fade_left">
                                <img src="/assets/images/banner/home1/bg.png" alt="Banner" />
                            </div>
                        </div>
                    </div>
                    <div className="banner-shape d-none d-lg-block">
                        <img src="/assets/images/banner/home1/shape-1.png" alt="Shape 1" className="banner-shape-1" data-speed="0.7" />
                        <img src="/assets/images/banner/home1/shape-2.png" alt="Shape 2" className="banner-shape-2" data-speed="0.8" />
                        <img src="/assets/images/banner/home1/shape-3.png" alt="Shape 3" className="banner-shape-3" data-speed="0.8" />
                        <img src="/assets/images/banner/home1/shape-4.png" alt="Shape 4" className="banner-shape-4" data-speed="0.7" />
                        <img src="/assets/images/banner/home1/shape-5.png" alt="Shape 5" className="banner-shape-5" />
                    </div>
                </section>

                    <section className="choose-area pt-140" id='features'>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div className="section-area text-center mb-50">
                                        <span className="section-subtitle tp_fade_left">Our Features</span>
                                        <h2 className="section-title tp_title_slideup mb-0">POWERFUL FEATURES</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {features.map((feature, index) => (
                                    <div className="col-xl-3 col-lg-4 col-md-6 tp_fade_left" key={feature.id} data-fade-from="left" data-delay={`${index * 0.3}`}>
                                        <div className="choose-item mb-30">
                                            <span className="choose-item-count">{String(feature.id).padStart(2, '0')}</span>
                                            <div className="choose-item-img">
                                                <img src={feature.imgSrc} alt="Image Not Found" className="resized-image" />
                                            </div>
                                            <div className="choose-item-content">
                                                <h5 className="choose-item-content-title">
                                                    <a href="#">{feature.title}</a>
                                                </h5>
                                                <p>{feature.description}</p>
                                                <a href="#" className="choose-item-content-btn">Learn More<i class="fa-solid fa-angle-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    <section className="feature-area pt-110 pb-110">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div className="section-area text-center mb-50">
                                        <span className="section-subtitle tp_fade_left">HOW IT WORKS</span>
                                        <h2 className="section-title tp_title_slideup mb-0">
                                            Add your data sources, train the AI, personalize it to fit your needs, and integrate it into your website.
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="feature-top mb-50 tp_fade_bottom">
                                {featureQuestions.map((feature, index) => (
                                    <div
                                        key={index}
                                        className="feature-item"
                                        onClick={() => setActiveQuestionIndex(index)}
                                    >
                                        <h5 className="feature-item-title">{feature.title}</h5>
                                        <p>{feature.description}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="row align-items-end">
                                <div className="col-xl-8 col-lg-8 col-sm-8">
                                    <div className={`feature-left-img ${activeQuestionIndex == 0 ? 'feature-left-img-visible' : 'feature-left-img-hide'} mb-30 tp_fade_right`}>
                                        <img src={featureQuestions[0].imgSrc} alt="Image Not Found" style={{ border: '6px solid #f14c47' }} />
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-sm-8">
                                    <div className={`feature-left-img ${activeQuestionIndex == 1 ? 'feature-left-img-visible' : 'feature-left-img-hide'} mb-30 tp_fade_right`}>
                                        <img src={featureQuestions[1].imgSrc} alt="Image Not Found" style={{ border: '6px solid #f14c47' }} />
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-sm-8">
                                    <div className={`feature-left-img ${activeQuestionIndex == 2 ? 'feature-left-img-visible' : 'feature-left-img-hide'} mb-30 tp_fade_right`}>
                                        <img src={featureQuestions[2].imgSrc} alt="Image Not Found" style={{ border: '6px solid #f14c47' }} />
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                    <div className="feature-right-img mb-30">
                                        <img src="assets/images/feature/bg-1.png" alt="Image Not Found" className="f-main-img tp_fade_left" />
                                        <img src="assets/images/feature/shape-1.png" alt="Image Not Found" className="feature-shape-1" data-speed="0.7" />
                                        <img src="assets/images/feature/shape-2.png" alt="Image Not Found" className="feature-shape-2" data-speed="0.8" />
                                        <img src="assets/images/feature/shape-3.png" alt="Image Not Found" className="feature-shape-3" data-speed="0.7" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="apps-area pt-140 pb-140">
                        <div className="container">
                            <div className="apps-icon tp_fade_bottom">
                                {appImages.map((image, index) => (
                                    <div className="apps-icon-item" key={index}>
                                        <img src={`${('assets/images/apps/' + image)}`} alt="Image Not Found" />
                                    </div>
                                ))}
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-9">
                                    <div className="apps-content">
                                        <h2 className="tp_title_slideup">
                                            Easily Bring AI in Your Workflow to Create Content
                                        </h2>
                                        <p className="tp_fade_bottom">
                                            There are many variations of passages of Lorem Ipsum available <br />
                                            but the majority have suffered alteration in some form, by more and <br />
                                            more injected humour.
                                        </p>
                                        <a href="#" className="theme-btn tp_fade_bottom">Integrate With Your App</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="apps-shape d-none d-md-block">
                            {shapeImages.map((shape, index) => (
                                <img 
                                    key={index}
                                    src={`${('assets/images/apps/' + shape.src)}`} 
                                    alt="Image Not Found" 
                                    className={`apps-shape-${index + 1}`} 
                                    data-speed={shape.speed}
                                />
                            ))}
                        </div>
                    </section>

                    <section className="price-area price-tab pt-140 pb-140" id='price'>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-9 col-md-10">
                                    <div className="section-area text-center mb-45">
                                        <span className="section-subtitle tp_fade_left">Pricing Plan</span>
                                        <h2 className="section-title tp_title_slideup mb-0 section-title tp_title_slideup-big">
                                            Personalized Pricing Plans Suited to You.
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="price-switch-wrapper">
                                        <label className={`toggler ${!isYearly ? 'toggler--is-active' : ''}`} onClick={togglePlan}>
                                            Pay Monthly
                                        </label>
                                        <div className="toggle">
                                            <input
                                                type="checkbox"
                                                id="switcher"
                                                className="tp-check"
                                                checked={isYearly}
                                                onChange={togglePlan}
                                            />
                                            <b className="switchdash"></b>
                                        </div>
                                        <label className={`toggler yearly-pack ${isYearly ? 'toggler--is-active' : ''}`} onClick={togglePlan}>
                                            Pay Yearly
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={isYearly ? 'hide' : ''}>
                                <div className="row align-items-end">
                                    {plans.map((plan, index) => (
                                        <div key={index} className="col-xl-3 col-lg-6 col-md-6 tp_fade_left">
                                            <div className={`price-item ${plan.bestChoice ? 'price-item-2' : ''}`}>
                                                <div className="price-item-head">
                                                    <h5 className="price-item-title">{plan.title}</h5>
                                                    {plan.monthly.price && (
                                                        <>
                                                          <h2 className="price-item-amount-title">{plan.monthly.price}</h2>
                                                            <p>{plan.monthly.description}</p>
                                                            <a
                                                                className="price-item-btn price-btn-2"
                                                                href={isAuth ? "/settings?tab=2" : "/login"}
                                                                onClick={(e) => {
                                                                    if (!isAuth) {
                                                                        e.preventDefault(); // Prevent default action
                                                                        navigate('/login'); // Navigate to the login page
                                                                    } else {
                                                                        navigate('/settings?tab=2'); // Navigate to settings if authenticated
                                                                    }
                                                                }}
                                                            >
                                                                Choose Plan <i className="fa-solid fa-angle-right"></i>
                                                            </a>
                                                        </>
                                                    )}
                                                </div>
                                                <ul className="price-item-list">
                                                    {plan.monthly.features && plan.monthly.features.map((feature, i) => (
                                                        <li key={i}>{feature}</li>
                                                    ))}
                                                </ul>
                                                {plan.monthly.contactUs && (
                                                    <a className="price-item-btn price-btn-4" href="#">
                                                        Contact us <i class="fa-solid fa-angle-right"></i>
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={isYearly ? '' : 'hide'}>
                                <div className="row align-items-end">
                                    {plans.map((plan, index) => (
                                        <div key={index} className="col-xl-3 col-lg-6 col-md-6 tp_fade_left">
                                            <div className={`price-item ${plan.bestChoice ? 'price-item-2' : ''}`}>
                                                <div className="price-item-head">
                                                    <h5 className="price-item-title">{plan.title}</h5>
                                                    {plan.yearly.price && (
                                                        <>
                                                            <h2 className="price-item-amount-title">{plan.yearly.price}</h2>
                                                            <p>{plan.yearly.description}</p>
                                                            <a
                                                                className="price-item-btn price-btn-2"
                                                                href={isAuth ? "/settings?tab=2" : "/login"}
                                                                onClick={(e) => {
                                                                    if (!isAuth) {
                                                                        e.preventDefault(); // Prevent default action
                                                                        navigate('/login'); // Navigate to the login page
                                                                    } else {
                                                                        navigate('/settings?tab=2'); // Navigate to settings if authenticated
                                                                    }
                                                                }}
                                                            >
                                                                Choose Plan <i className="fa-solid fa-angle-right"></i>
                                                            </a>

                                                        </>
                                                    )}
                                                </div>
                                                <ul className="price-item-list">
                                                    {plan.yearly.features && plan.yearly.features.map((feature, i) => (
                                                        <li key={i}>{feature}</li>
                                                    ))}
                                                </ul>
                                                {plan.yearly.contactUs && (
                                                    <a className="price-item-btn price-btn-4" href="#">
                                                        Contact us <i class="fa-solid fa-angle-right"></i>
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="testimonial-area pb-40 fix">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-xl-7 col-lg-8">
                                    <div class="section-area text-center my-5">
                                        <span class="section-subtitle tp_fade_left">Testimonials</span>
                                        <h2 class="section-title tp_title_slideup mb-0">ChatZippy Received  4.8/5 Stars in Over 10,000+ Reviews.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="testimonial-area pb-40 fix">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-xl-7 col-lg-8">
                                    <div class="section-area text-center my-5">
                                        <span class="section-subtitle tp_fade_left">OUR BLOGS</span>
                                        <h2 class="section-title tp_title_slideup mb-0">Watch Our Latest Blog News</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="brand-area pb-140">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-10">
                                    <div className="section-area text-center mb-50">
                                        <h2 className="section-title tp_title_slideup mb-0">
                                            Trusted by 20,000+ Marketing Departments.
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="brand-wrap">
                                {brands.map((brand, index) => (
                                    <div className="brand-item tp_fade_bottom" key={index}>
                                        <img src={(`assets/images/brand/${brand}`)} alt="Image Not Found" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    
                    
                    <Footer/>



            </div>

        </div>
    );
}

export default Dashboard;