import '../../../Admin/style.css';
import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../../Sidebars/Navbar.jsx';
import Header from '../../Sidebars/header.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UserContext } from '../../../TabContextProvider.jsx';
import UserApiClient from '../../../ApiClients/index.jsx';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function Allusers() {

    const { User } = useContext(UserContext);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    let isFetching = false;

    const [users, setUsers] = useState([]);

    async function handleUserStatusChange(e, id) {
        const res = await UserApiClient.put(`api/user/status/${id}/`, { is_active: e.target.checked }, true);
        if (res.success) {
            await fetchData();
        }
    }

    // Your API call to fetch data
    const fetchData = async (useLoader) => {
        isFetching = true
        try {
            const res = await UserApiClient.get(`api/user/list/`, {}, true, undefined, useLoader);
            if (res.success) {
                setUsers(res.data);
            }
            isFetching = false
        } catch (error) {
            console.log("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        if (User.id && !!token && !isFetching) {
            fetchData();
        }
    }, []);

    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [isMobCheck, setIsMobCheck] = useState();

    const handleResize = () => {
        setIsNavbarVisible(window.innerWidth >= 768);
    };

    useEffect(() => {
        setIsMobCheck(window.innerWidth >= 768);
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleNavbar = () => {
        setIsNavbarVisible(prevState => !prevState);
    };

    const handleDelete = async (id) => {
        await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await UserApiClient.delete(`api/user/delete/${id}/`, {}, true).then(async res => {
                    if (res.success) {
                        await fetchData();
                        Swal.fire(res.data.message, '', 'success', 3000)
                    }
                })
            }
        });
    };


    async function handleSourceSearch(e) {
        e.preventDefault();
        const searchTerm = e.target.searchTerm.value;

        // Reset the documents based on the current value
        if (searchTerm.trim() === "") {
            // If searchTerm is empty, show original data
            await fetchData(false);
            return; // Exit the function early
        }

        // // Filter based on the searchTerm
        const filteredData = users.filter(item =>
            item.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setUsers(filteredData);
    }
    
    const getPlanStyles = (subscriptionType) => {
        return {
            backgroundColor: subscriptionType.includes('gold') ? '#fdf3b9' :
                subscriptionType.includes('silver') ? '#C0C0C0' :
                    subscriptionType.includes('bronze') ? '#cd7f32' :
                        subscriptionType.includes('free') ? '#28C76F' : '#28C76F',
            border: `1px solid ${subscriptionType.includes('gold') ? '#fcd736' :
                subscriptionType.includes('silver') ? '#AFAFAF' :
                    subscriptionType.includes('bronze') ? 'brown' :
                        subscriptionType.includes('free') ? '#1D8A4C' : '#1D8A4C'}`
        };
    };

    return (
        <>
            <div className="container-fluid p-0">
                <Header toggleNavbar={toggleNavbar} isNavbarVisible={isNavbarVisible} />
                <div className="row">
                    <div
                        className={`sizefix col-md-0 col-lg-3 col-xl-2 p-0 ${isNavbarVisible ? '' : 'd-none'}`}
                        style={{ borderRight: "1px solid #ebebeb" }}
                    >
                        <Navbar />
                    </div>
                    {/* <div className="col-10 p-0"> */}
                    {/* <div className={`${isNavbarVisible ? 'col-10 p-0' : 'col-12 p-0'}`}> */}
                    <div className={`${isMobCheck ? (isNavbarVisible ? 'col-10 p-0' : 'col-12 p-0') : (isNavbarVisible ? 'd-none' : 'col-12 p-0')}`}>


                        <div className="main-sec mt-5">
                            <div className="serchblog pb-4">
                                <div className="d-flex flex-column flex-md-row align-items-center">
                                    <h5 className="m-0 mb-2 mb-md-0" style={{ width: "50%" }}>Users List</h5>
                                    <form className='searchbartab' onSubmit={handleSourceSearch}>
                                        <input type="text" placeholder="Search by email" name="searchTerm" className='m-0 rounded' style={{ width: "80%" }} />
                                        <button type="submit" className='border rounded'><i className="fa fa-search"></i></button>
                                    </form>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-12'>
                                    <div className="table-responsive">
                                        <table className="table category-tab text-start">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Full Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">User Since</th>
                                                    {/* <th scope="col">Plan</th> */}
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.map(user => (
                                                    <tr key={user.id}>
                                                        <td>#{user.id}</td>
                                                        <td className='text-capitalize'>{user.first_name} {user.last_name}</td>
                                                        <td>{user.email}</td>
                                                        <td>{user.date_joined}</td>
                                                        {/* <td>
                                                            <span style={{
                                                                // backgroundColor: user.plan?.subscription_type?.includes("free")? '#28C76F' : '#28C76F',
                                                                borderRadius: '20px',
                                                                padding: '3px 15px',
                                                                ...getPlanStyles(user.plan?.subscription_type)
                                                            }}>
                                                                {user.plan?.subscription_config?.plan}
                                                            </span>
                                                        </td> */}
                                                        <td>
                                                            <label className="switch">
                                                                <input type="checkbox" defaultChecked={user.is_active} onChange={(e) => handleUserStatusChange(e, user.id)} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <button className='icn-btn' onClick={() => navigate(`/user-profile/${user.id}`)}>
                                                                <i className="fa-solid fa-eye mx-2"></i>
                                                            </button>
                                                            <button className='icn-btn' onClick={() => handleDelete(user.id)}>
                                                                <i className="fa-solid fa-trash-can mx-2"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default Allusers;
