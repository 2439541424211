import '../../Admin/style.css';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserApiClient from '../../ApiClients';
import { UserContext } from '../../TabContextProvider';
import Swal from 'sweetalert2';

function Login() {

  const { User, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({})
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);


  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    await UserApiClient.post('api/admin-login/', formData).then(async res => {
      let data = await res.data
      if (res.success && data) {
        setUser(data.user);
        localStorage.setItem('token', data.access);
        localStorage.setItem('userId', data.user.id);
        navigate('/admin-dashboard');
        Swal.fire({
          icon: 'success',
          title: 'Welcome to admin dashboard.',
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: false,
          timer: 2000
        })
      } else {
        console.log(data.detail)
      }
    })
  };


  return (
    <>
      <div className="row aduser-login">
        <div className='col-sm-0 col-md-3 col-xl-3 logo-set'>
          <button type="button" className="btn backarrow mx-5 my-3" onClick={() => navigate('/')} style={{ fontSize: '20px', color: "#47a4e5", cursor: "pointer" }}>
            <i className="fa-solid fa-circle-arrow-left me-2" ></i>Back</button>
        </div>
        <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
          <div className='login-img'><img src="/assets/Layer_1.png" className="logo" alt="logo" style={{ width: "30%" }} /></div>
          <div className='signin-set'>
            <div className='signin'>
              <h2>ADMIN LOGIN</h2>
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <input type="email" className="form-control" onChange={handleChange} name='email' placeholder="Enter your email address" required />
                </div>
                <select className="form-select form-select-lg mb-3" style={{ fontWeight: "400", fontSize: "1rem" }} required>
                  <option value="admin">Admin</option>
                </select>
                <div className="form-group passicon">
                  <input type={isPasswordVisible ? 'text' : 'password'} className="form-control" name="password" onChange={handleChange} placeholder="Password" required />
                  <i className={`fa-regular fa-eye-slash ${isPasswordVisible ? 'd-none' : ''}`} onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} ></i>
                  <i className={`fa-solid fa-eye ${isPasswordVisible ? '' : 'd-none'}`} onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} ></i>
                </div>
                <button type="submit" className="btn btn-primary signin-btn mt-4">Log In</button>
              </form>
            </div>
          </div>
        </div>
        <div className='col-sm-0 col-md-3 col-xl-3 logo-set'>
        </div>
      </div>
    </>
  );
}

export default Login;
