import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../TabContextProvider';

function Navbar({ chatbotList }) {

    const { User, Team, Member } = useContext(UserContext);
    const [subType, setSubType] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (Team.plan?.subscription_type) {
            setSubType(Team.plan.subscription_type)
        }
    }, [Team])

    return (
        <>
            <div className="headlogo" onClick={() => { navigate('/') }}>
                <img src="/assets/Layer_1.png" className="logo" alt="logo" style={{ width: '25%', cursor: "pointer" }} /></div>
            <div className='navmain'>
                <ul>
                    <li>
                        <div className='navitems'><i className="fa fa-home" aria-hidden="true"></i>
                            <NavLink className="nav-link px-2 py-2" to={`/dashboard/${Team?.slug || ''}`}>Dashboard</NavLink>
                        </div>
                    </li>
                </ul>
                <div className="my-4" style={{ color: "gray" }}>
                    <h6>Chatbot Settings</h6>
                </div>

                <ul>
                    <li className="nav-li mt-2">
                        <i className="fa-solid fa-robot"></i>
                        {/* <a className="text-decoration-none ps-4 pe-2 py-2 chatpointer" data-bs-toggle="collapse" aria-expanded="false" onClick={UserChatbots} style={{ color: "#4a545d" }}>
                        <span className="menu-label">Chatbots</span>
                    </a> */}
                        <a className="dropdown-toggle text-decoration-none  ps-2 py-2" href="#settingMenuone" data-bs-toggle="collapse" aria-expanded="false" style={{ color: "#4a545d" }}>
                            <span className="menu-label">{Team.name?.length > 12 ? Team.name?.slice(0, 12) + "..." : Team.name || "Chatbots"}</span>
                        </a>
                        <ul className="collapse list-unstyled chatuserlist" id="settingMenuone">
                            {chatbotList && chatbotList.map((item, index) => (
                                <li key={index} className='my-2 text-capitalize'>
                                    <NavLink className="text-decoration-none submenus-style" style={{ color: "#4a545d" }} to={`/layout/${Team?.slug}/${item.chatbot_name}/${item.id}?tab=0`}>
                                        {/* <img src="/assets/Logo2.png" style={{ width: "18px", marginRight: "5px" }} /> */}
                                        {item.chatbot_name}</NavLink>
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>

                <div className="my-3" style={{ color: "gray" }}>
                    <h6>Others</h6>
                </div>

                <ul>
                    <li>
                        <div className='navitems'>
                            <i className="fa-solid fa-wallet"></i>
                            <NavLink className="nav-link px-2 py-2" to="/help">Help</NavLink>
                        </div>
                    </li>
                    {/* </ul> */}

                    {/* <ul>
                   
                    <li className="nav-li mt-2">
                        <i className="fa-solid fa-robot"></i>
                        <a className="dropdown-toggle text-decoration-none  ps-4 py-2" href="#settingMenuone" data-bs-toggle="collapse" aria-expanded="false" style={{ color: "#4a545d" }}>
                            <span className="menu-label">Chatbots</span>
                        </a>
                        <ul className="collapse list-unstyled ms-5 chatuserlist" id="settingMenuone">
                            <li className='my-2 ms-2 text-capitalize'>
                                <NavLink className="text-decoration-none submenus-style" style={{ color: "#4a545d" }} to={'/settings'}>Setting</NavLink><br/>
                                <NavLink className="text-decoration-none submenus-style" style={{ color: "#4a545d" }} to={'/settings'}>Account Setting</NavLink>
                            </li>
                        </ul>
                    </li>
                </ul> */}

                    {/* <ul> */}
                    <li>
                        <div className='navitems'>
                            <i className="fa-solid fa-gear"></i>
                            <NavLink className="nav-link px-2 py-2" to="/settings">Account Settings</NavLink>
                        </div>
                    </li>
                    {/* <li>
                    <div className='navitems'>
                        <i className="fa-solid fa-right-from-bracket"></i>
                        <NavLink className="nav-link px-4 py-2" to="/">Sign Out</NavLink>
                    </div>
                </li> */}

                </ul>
                {(subType.includes("monthly") || subType.includes("free")) && (Member.role == "owner" || Team.created_by === User.id) ? (
                    // <div className='upgradebox'>
                    //     <div className="upgradenow">
                    //         <h6 style={{ lineHeight: "inherit" }}>
                    //             Get 15% Amount off if you Subscribe Yearly!
                    //         </h6>
                    //         <button
                    //             type="button"
                    //             onClick={() => navigate('/settings?tab=2')}
                    //             className="btn btn-light"
                    //         >
                    //             Upgrade Now!
                    //         </button>
                    //     </div>

                    // </div>
                    ""
                ) : ""}
            </div>
            <div className='d-flex justify-content-center allrights'>
                <div className='adcopyright'>
                    <h6>ChatZippy</h6>
                    <h6 className="form-text text-muted"><i className="fa-regular fa-copyright"></i>2024 All Rights Reserved</h6>
                    <h6 className="form-text text-muted">Made with <i className="fa-solid fa-heart" style={{ color: "#ff0008" }}></i> by ChatZippy</h6>
                </div>
            </div>
        </>
    );
}

export default Navbar;