import '../../../Admin/style.css';
import React, { useState, useEffect } from 'react';
import Navbar from '../../Sidebars/Navbar.jsx';
import Header from '../../Sidebars/header.jsx';
import { useNavigate } from 'react-router-dom';

function Userinvoice() {
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);

    const handleResize = () => {
        setIsNavbarVisible(window.innerWidth >= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleNavbar = () => {
        setIsNavbarVisible(prevState => !prevState);
    };


    const navigate = useNavigate();
    const invoice = () => {
        navigate('/invoice');
    };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row">
                    <Header toggleNavbar={toggleNavbar} isNavbarVisible={isNavbarVisible} />
                    <div className="row">
                        <div
                            className={`sizefix col-md-0 col-lg-3 col-xl-2 p-0${isNavbarVisible ? '' : 'd-none'}`}
                            style={{ borderRight: "1px solid #ebebeb" }}
                        >
                            <Navbar />
                        </div>
                        <div className={`${isNavbarVisible ? 'col-10 p-0' : 'col-12 p-0'}`}>
                            <div className="main-sec">

                                <div className='serchblog p-4' >
                                    <div style={{ display: "flex", alignItems: "center" }} >
                                        <h5 className='m-0' style={{ width: "100%" }}>User Invoice</h5>
                                    </div>
                                    <button onClick={invoice} className='btn btn-primary px-4 py-2'>Back to Invoice list</button>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='userinvoice'>
                                            <div style={{ display: "flex", justifyContent: "end", marginBottom: "25px" }}>
                                                <i className="fa-solid fa-download mx-2"></i>
                                                <i className="fa-solid fa-pen-to-square mx-2"></i>
                                                <i className="fa-solid fa-trash-can mx-2"></i>
                                            </div>
                                            <div className='invoice-innrcontainer'>
                                                <div className='col-4'>
                                                    <img src="/assets/Layer_1.png" className="logo mb-2" alt="logo" style={{ width: "100%"}}/>
                                                    <h5>Office 149, 450 South Brank Brooklyn San Diego Country, CA 91905, USA +1 (123) 456.789</h5>
                                                </div>
                                                <div className='col-4 mt-5'>
                                                    <h2>Invoice #ET001</h2>
                                                    <h5>Date Issue: April 25, 2021</h5>
                                                    <h5>Date Due: May 25, 2021</h5>
                                                </div>
                                            </div>
                                            <div className='invoice-innrcontainers'>
                                                <div className='col-4 mt-5'>
                                                    <h2>Invoice To:</h2>
                                                    <h5>Office 149, 450 South Brank Brooklyn San Diego Country, CA 91905, USA +1 (123) 456.789</h5>
                                                </div>
                                                <div className='col-4 mt-5'>
                                                    <h2>Payment Method</h2>
                                                    <h5>Date Issue: April 25, 2021</h5>
                                                    <h5>Date Due: May 25, 2021</h5>
                                                </div>
                                            </div>
                                            <div className='invoice-innrcontainersss'>
                                                <div className='invoiceplan'>
                                                    <h3>Plan</h3>
                                                    <h3>Price</h3>
                                                </div>
                                                <div className='invoiceplandata'>
                                                    <div className='p-4'>
                                                        <h5>Gold</h5>
                                                        <h6> - EveryThing in free</h6>
                                                        <h6> - 2000 Messages</h6>
                                                        <h6> - 2 Chatbot</h6>
                                                        <h6> - API access </h6>
                                                        <h6> - Integration</h6>
                                                    </div>
                                                    <div className='p-4'>
                                                        <h4>$125/- Monthly</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='invoiceplandata'>
                                                <div className='p-4'>
                                                    <h5>Thanks for choosing us</h5>
                                                </div>
                                                <div className='p-4' style={{ textAlign: "end" }}>
                                                    <h5>Subtotal - $00.00</h5>
                                                    <h5>Discount - $50.00</h5>
                                                    <h5>Tax - $204.25</h5>
                                                    <hr />
                                                    <h5>Total - $154.25</h5>
                                                </div>
                                            </div>
                                            <hr />
                                            <div style={{ textAlign: "center" }}>
                                                <span>Note: It was a pleasure working with you and your team. We hope you will keep us in mind for future projects. Thank you</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Userinvoice;
