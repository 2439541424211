import Swal from "sweetalert2";
import UserApiClient from "../../../ApiClients";
import "../../style.css"
import React, { useEffect, useState } from 'react';

function Plans() {

    const [planData, setPlanData] = useState({});
    let isFetching = false;
  

    const handlePlanChange = (e) => {
        // console.log(plan_type);
        const { name, value } = e.target;
        setPlanData({ ...planData, [name]: value});
    };

    const handleFromSubmit = async (e) => {
        e.preventDefault();

        console.log(planData);
        await UserApiClient.post(`bot/plans/update/`, planData, true).then(async (res) => {
            console.log(res);
            if (res.success) {
                Swal.fire(res.data.message, '', 'success', 3000)
            }
        });
    };
    async function fetchData() {
        isFetching = true
        await UserApiClient.get(`bot/plans/get/`, {}, false).then(async res => {
            if (res.success) {
                setPlanData(res.data);
                isFetching = false
            }
        })
    }
    
    useEffect(() => {
        if (!isFetching) fetchData();
    }, [])

    
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='account-setting'>
                        <form className="row" onSubmit={handleFromSubmit}>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h3 className="mb-4"><strong>Monthly</strong></h3>
                            <label htmlFor="bronze">Bronze</label>
                                <div className="form-floating mb-1" >
                                    <input type="text" defaultValue={planData.bronze_monthly_plan} maxLength={255}  name="bronze_monthly_plan" id="bronze" onChange={handlePlanChange} className="form-control m-0" placeholder="First name" required />
                                    <label htmlFor="bronze" style={{fontWeight: "200"}}>Title</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="number" defaultValue={planData.bronze_monthly_price} maxLength={255}  name="bronze_monthly_price" id="price_bronze" onChange={handlePlanChange} className="form-control" placeholder="First name" required />
                                    <label htmlFor="price_bronze" style={{fontWeight: "200"}}>Price</label>
                                </div>
                            <label htmlFor="silver">Silver</label>
                                <div className="form-floating mb-1" >
                                    <input type="text" defaultValue={planData.silver_monthly_plan} maxLength={255}  name="silver_monthly_plan" id="silver" onChange={handlePlanChange} className="form-control m-0" placeholder="First name" required />
                                    <label htmlFor="silver" style={{fontWeight: "200"}}>Title</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="number" defaultValue={planData.silver_monthly_price} maxLength={255}  name="silver_monthly_price" id="plan_silver" onChange={handlePlanChange} className="form-control" placeholder="First name" required />
                                    <label htmlFor="plan_silver" style={{fontWeight: "200"}}>Price</label>
                                </div>
                            <label htmlFor="premium">Gold</label>
                                <div className="form-floating mb-1" >
                                    <input type="text" defaultValue={planData.gold_monthly_plan} maxLength={255}  name="gold_monthly_plan" id="premium" onChange={handlePlanChange} className="form-control m-0" placeholder="First name" required />
                                    <label htmlFor="premium" style={{fontWeight: "200"}}>Title</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="number" defaultValue={planData.gold_monthly_price} maxLength={255}  name="gold_monthly_price" id="price_premium" onChange={handlePlanChange} className="form-control" placeholder="First name" required />
                                    <label htmlFor="price_premium" style={{fontWeight: "200"}}>Price</label>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <h3 className="mb-4"><strong>Yearly</strong></h3>
                            <label htmlFor="bronze">Bronze</label>
                                <div className="form-floating mb-1" >
                                    <input type="text" defaultValue={planData.bronze_yearly_plan} maxLength={255}  name="bronze_yearly_plan" id="bronze" onChange={handlePlanChange} className="form-control m-0" placeholder="First name" required />
                                    <label htmlFor="bronze" style={{fontWeight: "200"}}>Title</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="number" defaultValue={planData.bronze_yearly_price} maxLength={255}  name="bronze_yearly_price" id="price_bronze" onChange={handlePlanChange} className="form-control" placeholder="First name" required />
                                    <label htmlFor="price_bronze" style={{fontWeight: "200"}}>Price</label>
                                </div>
                            <label htmlFor="silver">Silver</label>
                                <div className="form-floating mb-1" >
                                    <input type="text" defaultValue={planData.silver_yearly_plan} maxLength={255}  name="silver_yearly_plan" id="silver" onChange={handlePlanChange} className="form-control m-0" placeholder="First name" required />
                                    <label htmlFor="silver" style={{fontWeight: "200"}}>Title</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="number" defaultValue={planData.silver_yearly_price} maxLength={255}  name="silver_yearly_price" id="price_silver" onChange={handlePlanChange} className="form-control" placeholder="First name" required />
                                    <label htmlFor="price_silver" style={{fontWeight: "200"}}>Price</label>
                                </div>
                            <label htmlFor="premium">Gold</label>
                                <div className="form-floating mb-1" >
                                    <input type="text" defaultValue={planData.gold_yearly_plan} maxLength={255}  name="gold_yearly_plan" id="premium" onChange={handlePlanChange} className="form-control m-0" placeholder="First name" required />
                                    <label htmlFor="premium" style={{fontWeight: "200"}}>Title</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="number" defaultValue={planData.gold_yearly_price} maxLength={255}  name="gold_yearly_price" id="price_premium" onChange={handlePlanChange} className="form-control" placeholder="First name" required />
                                    <label htmlFor="price_premium" style={{fontWeight: "200"}}>Price</label>
                                </div>
                            </div>

                            {/* <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    extraPlugins: [MyCustomUploadAdapterPlugin],
                                }}
                                data={gold}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setGold(data);
                                }}
                                onReady={(editor) => {
                                    editor.editing.view.change((writer) => {
                                        writer.setStyle('height', '250px', editor.editing.view.document.getRoot());
                                    });
                                }}
                            /> */}

                            <div className="row mt-5 mx-auto">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary" style={{ width: "100%" }}>Save Changes</button>
                                </div>
                                {/* <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <button type="reset" className="btn btn-secondary" style={{ width: "100%" }}>Reset</button>
                                </div> */}
                            </div>

                        </form>
                    </div>
                </div>

            </div>

        </>
    );
}

export default Plans;
