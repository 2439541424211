import '../../../App.css';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../TabContextProvider';
import UserApiClient from '../../../ApiClients';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Swal from 'sweetalert2';
import loader from '../../../Configs/loader';
import { loadStripe } from '@stripe/stripe-js';
import { StripePublicKey } from '../../../Configs/enviroment';

// Load your publishable key here
const stripePromise = loadStripe(StripePublicKey);

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Billing({ chatbotList }) {
    const navigate = useNavigate();
    const { User, Team } = useContext(UserContext);
    const [addOns, setAddOns] = useState([]);
    const [plan, setPlan] = useState({});
    const [planConfig, setPlanConfig] = useState({});
    const [planData, setPlanData] = useState({});
    const [invoives, setInvoives] = useState([]);
    const [value, setValue] = useState(0);
    let isFetching = false;
    let isFetchingRecurring = false;
    let isFetchingInvoice = false;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchData() {
        isFetching = true
        await UserApiClient.get(`bot/plans/get/`, {}, false).then(async res => {
            if (res.success) {
                setPlanData(res.data);
                isFetching = false
            }
        })
    }

    async function fetchRecurringData() {
        isFetchingRecurring = true;
        await UserApiClient.get(`api/payment/create/session/${Team.id}/`, {}, true).then(async res => {
            if (res.success) {
                setPlan(res.data);
                setAddOns(res.data.add_on);
                Team.plan = res.data;
                isFetchingRecurring = false;
            }
        })
    }

    async function createPaymentSession(subscription_type) {
        const data = {
            "subscription_type": subscription_type,
            }
        await UserApiClient.post(`api/payment/create/session/${Team.id}/`, data, true).then(async res => {
            if (res.success) {
                    const stripe = await stripePromise;
                    const { error } = await stripe.redirectToCheckout({
                      sessionId: res.data.sessionId, // Use the session ID returned from your API
                    });
                if (error) {
                        Swal.fire({
                            icon: 'error',
                            title: "Failed to create payment session. Please try again.",
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: false,
                            timer: 3000
                        })

                    }
            }
//             setTimeout(() => { navigate('/settings?tab=2') }, 2000);
        })
    }

    async function fetchInvoiceData() {
        isFetchingInvoice = true;
        await UserApiClient.get(`api/invoices/?team_id=${Team.id}`, {}, true).then(async res => {
            if (res.success) {
                setInvoives(res.data.data);
                isFetchingInvoice = false;
            }
        })
    }

    async function handlePauseResumeSubscription() {
        await Swal.fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `Yes, ${plan.is_paused ? 'Resume' : 'Pause'}!`
        }).then(async (result) => {
            if (result.isConfirmed) {
                await UserApiClient.post(`api/recurring/${plan.is_paused ? 'resume' : 'pause'}/${Team.id}/`, {}, true).then(async res => {
                    if (res.success) {
                        fetchRecurringData();
                        Swal.fire(res.data.message, '', 'success', 3000);
                    }
                })
            }
        });
    }


    function getPopupText(value) {
        if (value == 'domain') {
            return `<div class="mt-3 w-100 text-left">
                <h4 class="text-lg font-weight-medium text-dark">Custom Domains</h4>
                <div class="text-muted mt-2">
                    <p>By clicking <strong>Enable</strong>, you will subscribe to <strong>Custom Domains</strong> for <strong>$59 / month</strong>.</p>
                </div>
                <div class="d-flex align-items-center justify-content-between font-weight-semibold text-dark mt-3">
                    <p class="mb-0">Custom Domains</p>
                    <p class="mb-0">$59 / month</p>
                </div>
            </div>`
        } else if (value == 'branding') {
            return `<div class="mt-3 w-100 text-left">
                <h4 class="text-lg font-weight-medium text-dark">Remove 'Powered By Chatbase'</h4>
                <div class="text-muted mt-2">
                    <p>By clicking <strong>Enable</strong>, you will subscribe to <strong>Remove 'Powered By Chatbase'</strong> for <strong>$39 / month</strong>.</p>
                </div>
                <div class="d-flex align-items-center justify-content-between font-weight-semibold text-dark mt-3">
                    <p class="mb-0">Remove 'Powered By Chatbase'</p>
                    <p class="mb-0">$39 / month</p>
                </div>
            </div>`
        } else if (value == 'message') {
            return `<div class="mt-3 w-100 text-left">
                <h4 class="font-weight-semibold text-xl text-dark">Extra message credits</h4>
                <div class="text-muted mt-2">
                    <div class="d-flex flex-row gap-2">
                    <span class="align-self-center text-sm">I want to buy</span>
                    <input type="number" id="input_value" class="form-control w-25" min="1" max="999" value="1">
                    <span class="align-self-center text-sm">extra message credits</span>
                    </div>
                    <hr class="my-2 bg-secondary">
                    <div class="d-flex flex-column">
                    <span class="font-weight-semibold text-lg text-dark">$7 per 1000 massage</span>
                    <span class="text-xs text-muted">Total per month</span>
                    </div>
                </div>
            </div>`
        } else if (value == 'chatbot') {
            return `<div class="mt-3 w-100 text-left">
                <h4 class="font-weight-semibold text-xl text-dark">Extra chatbots</h4>
                <div class="text-muted mt-2">
                    <div class="d-flex flex-row gap-2">
                    <span class="align-self-center text-sm">I want to buy</span>
                    <input type="number" id="input_value" class="form-control w-25" min="1" max="999" value="1">
                    <span class="align-self-center text-sm">extra chatbots</span>
                    </div>
                    <p class="mt-4 text-xs text-dark">Note: you will be immediately charged a prorated amount for the remaining days.</p>
                    <hr class="my-2 bg-secondary">
                    <div class="d-flex flex-column">
                    <span class="font-weight-semibold text-lg text-dark">$10 per chatbot</span>
                    <span class="text-xs text-muted">Total per month</span>
                    </div>
                </div>
            </div>`
        }
    }


    async function handleExtraCreditChange(e) {
        const { name, value, checked } = e.target;

        if (!checked) {
            await Swal.fire({
                title: "Are you sure?",
                text: `You won't be able to revert this!`,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Yes, sure!`
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await UserApiClient.delete(`api/recurring/add-on/${Team.id}/`, {"add_on": name}, true).then(async res => {
                        await fetchRecurringData();
                        if (res.success) {
                            Swal.fire({
                                icon: 'success',
                                title: res.data.message,
                                showCancelButton: false,
                                showCloseButton: false,
                                showConfirmButton: false,
                                timer: 3000
                            })
                        }
                    })
                } else {
                    e.target.checked = !checked;
                }
            });
            return;
        }

        const amount = parseInt(value)

        Swal.fire({
            title: "",
            html: getPopupText(name),
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Add New",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                if (name == 'message' || name == 'chatbot') {
                    const input_value = Swal.getPopup().querySelector('#input_value').value;
                    // Validate the fields
                    if (!input_value) {
                        Swal.showValidationMessage(`input_value fields is required`);
                        return;
                    }
                    return {
                        "amount": parseInt(input_value) * amount,
                        "type": name
                    };
                } else {
                    return {
                        "amount": amount,
                        "type": name
                    };
                }

            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                    navigate(`/payment/add-on?team=${Team.id}&email=${User.email}&amount=${result.value.amount}&type=${result.value.type}`)
                }
            } else {
                e.target.checked = !checked
            }
        });
    }


    useEffect(() => {
        if (!isFetching) fetchData();
        if (!isFetchingRecurring && Team.created_by) fetchRecurringData();
        if (!isFetchingInvoice && Team.created_by) fetchInvoiceData();
    }, [Team])


    useEffect(() => {
        if (plan.subscription_config) {
            setPlanConfig(plan.subscription_config);
        }
    }, [plan])

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='account-setting'>
                        <div className='billingContainer'>
                            <h4><strong>Current Plan</strong></h4>
                            <div className='row'>
                                <div className='col-6'>
                                    <label className='mt-3' for="firstName">Subscription Details</label>
                                    <p className='mb-3' for="firstName">You are on the
                                        <span style={{ fontSize: "12px", margin: '0px 5px', backgroundColor: '#47a4e58c', borderRadius: '20px', padding: '2px 10px', border: '1px solid #4c8afa' }} >{planConfig.plan}</span>
                                        plan {planConfig.subscription_type == 'free' ? (<strong>free forever</strong>) : ''}</p>
                                    <div className='p-4'>
                                        <p className='mb-2'><i className="fa-solid fa-circle-check green-icon"></i>
                                            {plan.subscription_type != 'enterprise_subscription' ? `${plan.messages}${addOns?.map((item) => (item.type === 'message' ? item.add_on_expiry === false ? ` + ${item.message}` : undefined : undefined))}`.replaceAll(",", '')
                                                : 'Unlimited'}

                                            {" message credits/month"}
                                        </p>
                                        <p className='mb-2'><i className="fa-solid fa-circle-check green-icon"></i>
                                            {plan.subscription_type != 'enterprise_subscription' ?
                                                `${plan.chatbots}${addOns?.map((item) => (item.type === 'chatbot' ? item.add_on_expiry === false ? ` + ${item.chatbot}` : undefined : undefined))}`.replaceAll(",", '')
                                                : 'Unlimited'}
                                            {" chatbot"}
                                        </p>
                                        <p className='mb-2'><i className="fa-solid fa-circle-check green-icon"></i> {plan.subscription_type != 'enterprise_subscription' ? plan.embeddings : 'Unlimited'} characters/chatbot</p>
                                        <p className='mb-2'><i className="fa-solid fa-circle-check green-icon"></i> Limit to {plan.links == -1 ? 'Unlimited' : plan.links} links to train on</p>
                                        <p className='mb-2'><i className="fa-solid fa-circle-check green-icon"></i> Limit to {plan.subscription_type != 'enterprise_subscription' ? plan.team_members : 'Unlimited'} members to invite</p>
                                        <p className='mb-2'><i className="fa-solid fa-circle-check green-icon"></i> Embed on {plan.embedded == -1 ? 'Unlimited' : plan.embedded} websites</p>
                                        <p className='mb-2'><i className="fa-solid fa-circle-check green-icon"></i> View chat history</p>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <label className='my-3' for="firstName">Usage</label>
                                    <div className='p-4'>
                                        <p>Credits consumed: <strong>{'0'}</strong></p>
                                        <p>Subscription limit: <strong>20</strong></p>
                                        <p>Your credits renew at the start of every calendar month but your extra credits will remain as it is.</p>
                                        <p>Next renewal: <strong>{plan.expiry_date}</strong></p>
                                    </div>
                                </div>
                            </div>
                            {/* Pause & Resume Btn */}
                            <div className='row'>
                                <div className='text-center'>
                                    {plan.subscription_type != 'enterprise_subscription' ? (
                                        <button className='btn btn-primary' style={{ width: "20%" }} onClick={handlePauseResumeSubscription}>{plan.is_paused ? "Resume" : "Pause"} Subscription</button>
                                    ) : ""}
                                </div>
                            </div>
                            <div className='mt-4'>
                                <div className='d-flex justify-content-center'>
                                    <div className='mainswitch blling-sec'>
                                        <Box sx={{ border: '1px ridge', backgroundColor: '#ffffff', padding: '8px', borderRadius: '50px' }}>
                                            <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
                                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                                    <Tab label="Monthly" {...a11yProps(0)} />
                                                    <Tab label="Yearly" {...a11yProps(1)} />
                                                </Tabs>
                                            </Tabs>
                                        </Box>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                    <div className='mainswitch blling-sec'>
                                        {/* <div className='monthly'>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                                    <Tab label="Monthly" {...a11yProps(0)} />
                                                    <Tab label="Yearly" {...a11yProps(1)} />
                                                </Tabs>
                                            </Box>
                                        </div> */}
                                    </div>
                                </div>
                                <CustomTabPanel value={value} index={0}>
                                    <div className="row justify-content-between inner-plan">
                                        <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4'>
                                            <div className='plancontain p-0' >
                                                <h4 className='planD'>{planData.bronze_monthly_plan || 'Plan'} <br /><h3 className='mt-2 mb-0'>$ {planData.bronze_monthly_price || "0.00"} <span>  /Month</span></h3></h4>
                                                <div className='d-flex'>
                                                    <div className='col-12 my-2'>
                                                        <h6><i className="fa-solid fa-check me-2"></i>200 Messages</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>1,000,000 Embedding Characters</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>2 Team Members</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>2 Chatbots</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>15 Links to Train On</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Standard Support</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>No API Access (Coming Soon)</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Full Custom Domain for Chatbot</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Remove Branding Not Available</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>2 Websites Embedded On</h6>
                                                    </div>
                                                </div>
                                                <div className='text-center'><button type="button" className="btn btn-primary w-75 mt-2 mb-4" onClick={() => { createPaymentSession(planData.bronze_monthly_subscription_type) }} disabled={plan.subscription_type == planData.bronze_monthly_subscription_type}>{plan.subscription_type == planData.bronze_monthly_subscription_type ? "Subscribed" : "Subscribe"}</button></div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4'>
                                            <div className='plancontain p-0' >
                                                <h4 className='planD'>{planData.gold_monthly_plan || 'Plan'} <br /><h3 className='mt-2 mb-0'>$ {planData.gold_monthly_price || "0.00"} <span>  /Month</span></h3></h4>
                                                <div className='d-flex'>
                                                    <div className='col-12 my-2'>
                                                        <h6><i className="fa-solid fa-check me-2"></i>5,000 Messages</h6>
                                                        <h6><i className="fa-solid fa-check me-1"></i>10,000,000 Embedding Characters</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>10 Team Members</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>10 Chatbots</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Unlimited Links to Train On</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Premium Support</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Full API Access (Coming Soon)</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Full Custom Domain for Chatbot</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Remove Branding Available</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Unlimited Websites Embedded On</h6>
                                                    </div>
                                                </div>
                                                <div className='text-center'><button type="button" className="btn btn-primary w-75 mt-2 mb-4" onClick={() => { createPaymentSession(planData.gold_monthly_subscription_type) }} disabled={plan.subscription_type == planData.gold_monthly_subscription_type}>{plan.subscription_type == planData.gold_monthly_subscription_type ? "Subscribed" : "Subscribe"}</button></div>
                                            </div>
                                        </div>
                                    </div>
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={1}>
                                    <div className="row justify-content-between inner-plan">
                                        <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4'>
                                            <div className='plancontain p-0' >
                                                <h4 className='planD'>{planData.bronze_yearly_plan || "Plan"} <br /><h3 className='mt-2 mb-0'>$ {planData.bronze_yearly_price || "0.00"} <span>  /Year</span></h3>
                                                    <span className='discountimg'><img src="/assets/discount15.png" className="logo mixblend" alt="logo" /></span>
                                                </h4>
                                                <div className='d-flex'>
                                                    <div className='col-12 my-2'>
                                                        <h6><i className="fa-solid fa-check me-2"></i>200 Messages</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>1,000,000 Embedding Characters</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>2 Team Members</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>2 Chatbots</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>15 Links to Train On</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Standard Support</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>No API Access (Coming Soon)</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Full Custom Domain for Chatbot</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Remove Branding Not Available</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>2 Websites Embedded On</h6>
                                                    </div>
                                                </div>
                                                <div className='text-center'><button type="button" className="btn btn-primary w-75 mt-2 mb-4" onClick={() => { createPaymentSession(planData.bronze_yearly_subscription_type) }} disabled={plan.subscription_type == planData.bronze_yearly_subscription_type}>{plan.subscription_type == planData.bronze_yearly_subscription_type ? "Subscribed" : "Subscribe"}</button></div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4'>
                                            <div className='plancontain p-0' >
                                                <h4 className='planD'>{planData.gold_yearly_plan || "Plan"} <br /><h3 className='mt-2 mb-0'>$ {planData.gold_yearly_price || "0.00"} <span>  /Year</span></h3>
                                                    <span className='discountimg'><img src="/assets/discount15.png" className="logo mixblend" alt="logo" /></span>
                                                </h4>
                                                <div className='d-flex'>
                                                    <div className='col-12 my-2'>
                                                        <h6><i className="fa-solid fa-check me-2"></i>5,000 Messages</h6>
                                                        <h6><i className="fa-solid fa-check me-1"></i>10,000,000 Embedding Characters</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>10 Team Members</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>10 Chatbots</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Unlimited Links to Train On</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Premium Support</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Full API Access (Coming Soon)</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Full Custom Domain for Chatbot</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Remove Branding Available</h6>
                                                        <h6><i className="fa-solid fa-check me-2"></i>Unlimited Websites Embedded On</h6>
                                                    </div>
                                                </div>
                                                <div className='text-center'><button type="button" className="btn btn-primary w-75 mt-2 mb-4" onClick={() => { createPaymentSession(planData.gold_yearly_subscription_type) }} disabled={plan.subscription_type == planData.gold_yearly_subscription_type}>{plan.subscription_type == planData.gold_yearly_subscription_type ? "Subscribed" : "Subscribe"}</button></div>
                                            </div>
                                        </div>
                                    </div>
                                </CustomTabPanel>
                            </div>
                            {/* <hr />
                            <div className="row mt-5">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <button type="button" className="btn btn-primary" style={{ width: "100%" }}>Upgrade Plan</button>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <button type="button" className="btn btn-secondary" style={{ width: "100%" }}>Manage Subscription</button>
                                </div>
                            </div> */}
                        </div>
                        <div className='account-setting'>
                            <div className="row">
                                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                    <div className='planadd'>
                                        <div style={{ minHeight: "100px" }}>
                                            <h4>Extra message credits</h4>
                                            {/* <span>When your credits fall below the threshold you set, we'll automatically add credits that don't expire to your account, ensuring uninterrupted service.</span> */}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center pt-4'>
                                            <div>
                                                <h4><strong>$7 per 1000 messages / month</strong></h4>
                                                {/* <span>Total per 1000 message credits</span> */}
                                            </div>
                                            <div className=''>
                                                <label className="switch">
                                                    <input type="checkbox" onChange={handleExtraCreditChange} value="7" name='message'
                                                        checked={addOns?.some((item) => item.type === 'message' && item.add_on_expiry === false)}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                    <div className='planadd'>
                                        <div style={{ minHeight: "100px" }}>
                                            <h4>Extra chatbots</h4>
                                            {/* <span>$7 per 1000 messages / month</span> */}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center pt-4'>
                                            <div>
                                                <h4><strong>$10 per chatbot / month</strong></h4>
                                                {/* <span>Total per month</span> */}
                                            </div>
                                            <div className=''>
                                                <label className="switch">
                                                    <input type="checkbox" onChange={handleExtraCreditChange} value="10" name='chatbot'
                                                        checked={addOns?.some((item) => item.type === 'chatbot' && item.add_on_expiry === false)}
                                                    />
                                                    <span className="slider round"></span>
                                                </label></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                    <div className='planadd'>
                                        <div style={{ minHeight: "100px" }}>
                                            <h4>Custom Domains</h4>
                                            {/* <span></span> */}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center pt-4'>
                                            <div>
                                                <h4><strong>$59 / month</strong></h4>
                                                <span>Use your own custom domains for the embed script, iframe, and chatbot link</span>
                                            </div>
                                            <div className=''>
                                                <label className="switch">
                                                    <input type="checkbox" onChange={handleExtraCreditChange} value="59" name='domain'
                                                        checked={addOns?.some((item) => item.type === 'domain' && item.add_on_expiry === false)}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                    <div className='planadd'>
                                        <div style={{ minHeight: "100px" }}>
                                            <h4>Remove 'Powered By Chatbase'</h4>
                                            {/* <span>$39 / month</span> */}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center pt-4'>
                                            <div>
                                                <h4><strong>$49 / month</strong></h4>
                                                <span>Remove the Chatbase branding from the iframe and widget</span>
                                            </div>
                                            <div className=''>
                                                <label className="switch">
                                                    <input type="checkbox" onChange={handleExtraCreditChange} value="49" name='branding'
                                                        checked={addOns?.some((item) => item.type === 'branding' && item.add_on_expiry === false)}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                        <div className='billingtable mt-4'>
                            <label className='my-2' for="firstName">Billing History</label>
                            <div className='col-12 p-0'>
                                <div className="table-responsive">
                                    <table className="table category-tab text-start">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Sr.No.</th>
                                                <th scope="col">Subscription Type</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Created On</th>
                                                <th scope="col">Payment</th>
                                                {/* <th scope="col">Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoives.map(item => (
                                                <tr key={item.id}>
                                                    <td>#{item.id}</td>
                                                    <td>{item.subscription_type}</td>
                                                    <td>{item.amount}</td>
                                                    <td>
                                                        {item.created_at}
                                                    </td>
                                                    <td>
                                                        <span style={{
                                                            backgroundColor: item.invoice_status === 'success' ? '#fdf3b9' : '#efdac5',
                                                            borderRadius: '20px',
                                                            padding: '1px 15px',
                                                            fontSize: "14px",
                                                            textTransform: 'capitalize',
                                                            border: `1px solid ${item.invoice_status === 'success' ? '#fcd736' : 'brown'}`
                                                        }}>
                                                            {item.invoice_status}
                                                        </span>
                                                    </td>
                                                    {/* <td>
                                                        <button className='icn-btn'>
                                                            <i className="fa-solid fa-trash-can mx-2"></i>
                                                        </button>
                                                    </td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </>
    );
}

export default Billing;
