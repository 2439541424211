import '../../Admin/style.css';
import React from 'react';
import Navbar from '../Sidebars/Navbar.jsx';
import Header from '../Sidebars/header.jsx';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import CustomClassicEditor from './ckeditor/custom-build.js';

function CreateBlog() {
    const navigate = useNavigate();

    const backToBlog = () => {
        navigate('/blogs');
    };

    const publishblog = () => {
        navigate('/single-blog');
    };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-2 p-0" style={{ borderRight: "1px solid #ebebeb" }}>
                        <Navbar />
                    </div>
                    <div className="col-10 p-0">
                        <Header />
                        <div className="main-sec">
                            <div className='serchblog'>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <h5 className='m-0'>Create Blog -</h5>
                                    <h5 className='ml-1 mb-0'>Blog List</h5>
                                </div>
                                <button onClick={backToBlog} className='btn btn-primary px-4 py-2'>Back to Blog List</button>
                            </div>
                            <div className='row createblog mt-5'>
                                <div className='col-8'>
                                    <div className="form-group">
                                        <label htmlFor="usr"><strong>Blog Title:</strong></label>
                                        <input type="text" className="form-control my-2" id="usr" />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="blogContent"><strong>Blog Content:</strong></label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data="<p>Write your blog content here...</p>"
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                console.log(data);
                                            }}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="Blogdis"><strong>Blog Description</strong></label>
                                        <input type="text" className="form-control my-2" id="Blogdis" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Metatitle"><strong>Meta Title:</strong></label>
                                        <input type="text" className="form-control my-2" id="Metatitle" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Metadis"><strong>Meta Description:</strong></label>
                                        <input type="text" className="form-control my-2" id="Metadis" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="metakey"><strong>Meta Keywords:</strong></label>
                                        <input type="text" className="form-control my-2" id="metakey" />
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className="form-group">
                                        <label htmlFor="roleSelect" className="form-label"><strong>Categories</strong></label>
                                        <select id="roleSelect" className="form-select form-select-lg my-2" aria-label=".form-select-lg example">
                                            <option selected>Select categories</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className='mt-0' htmlFor="tags"><strong>Tags</strong></label>
                                        <input type="text" className="form-control mt-0" id="tags" aria-describedby="emailHelp" placeholder="" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="featuredImage" className="form-label"><strong>Featured Image:</strong></label>
                                        <input type="file" id="featuredImage" className="form-control" accept="image/*" />
                                    </div>

                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary signin-btn py-3 mt-4" onClick={publishblog}>Publish Blog</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateBlog;
