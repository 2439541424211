import "../App.css";
import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Account from './Customs/setting/Account.jsx';
import Billing from './Customs/setting/Billing.jsx';
import Security from './Customs/setting/Security.jsx';
import Teams from './Customs/setting/Teams.jsx';
import { useLocation } from "react-router-dom";
import { UserContext } from "../TabContextProvider.jsx";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 4 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function MainSetting({ chatbotList }) {
    const { User, Member, Team } = useContext(UserContext);
    const [value, setValue] = useState(0);
    const [isMobCheck, setIsMobCheck] = useState(window.innerWidth >= 768);
    const location = useLocation();

    const handleResize = () => {
        setIsMobCheck(window.innerWidth >= 768);
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
        // Update URL to reflect the current tab
        const params = new URLSearchParams(window.location.search);
        params.set('tab', newValue);
        window.history.replaceState(null, '', `?${params.toString()}`);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tabIndex = parseInt(params.get('tab'), 10);
        if (!isNaN(tabIndex) && tabIndex >= 0 && tabIndex <= 4) {
            setValue(tabIndex);
        }
    }, [location.search]);

    return (
        <>
            <div className="main-sec">
                <div className='row'>
                    <div className={`col-sm-12 col-md-12 ${Member.role == "owner" || Team.created_by === User.id ? 'col-lg-10 col-xl-10' : 'col-lg-3 col-xl-3'} mainswitch`}>
                        <Box sx={{ border: '1px ridge', backgroundColor: '#ffffff', padding: '8px', borderRadius: '50px' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
                                <Tab label="Account" {...a11yProps(0)} />
                                <Tab label="Security" {...a11yProps(1)} />
                                {Member.role == "owner" || Team.created_by === User.id ? (
                                    <Tab label="Billing / Plans" {...a11yProps(2)} />
                                ) : ""}
                                {Member.role == "owner" || Team.created_by === User.id ? (
                                    <Tab label="Teams" {...a11yProps(3)} />
                                ) : ""}
                            </Tabs>
                        </Box>
                    </div>
                    <div className='col-6'>
                    </div>
                </div>
                <CustomTabPanel value={value} index={0}>
                    <Account />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Security />
                </CustomTabPanel>
                {Member.role == "owner" || Team.created_by === User.id ? (
                    <CustomTabPanel value={value} index={2}>
                        <Billing chatbotList={chatbotList}/>
                    </CustomTabPanel>
                ) : ""}
                {Member.role == "owner" || Team.created_by === User.id ? (
                    <CustomTabPanel value={value} index={3}>
                        <Teams />
                    </CustomTabPanel>
                ) : ""}
            </div>
        </>
    );
}

export default MainSetting;
