import React from 'react';
import { useNavigate } from "react-router-dom";

function PlanAdd() {
    const navigate = useNavigate();

    const addon=()=>{
        navigate('/settings?tab=2');
    }

    return (
        <>
            <div>
                <h2>Elevate your plan with add-ons</h2>
            </div>
            <div className="row">
                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                    <div className='planadd'>
                        <div style={{ minHeight: "100px" }}>
                            <h4>Auto recharge credits</h4>
                            <span>When your credits fall below the threshold you set, we'll automatically add credits that don't expire to your account, ensuring uninterrupted service.</span>
                        </div>
                        <div className='d-flex justify-content-between align-items-center pt-4'>
                            <div>
                                <h4><strong>$9</strong></h4>
                                <span>Total per 1000 message credits</span>
                            </div>
                            <div className=''><button type="button" onClick={addon} class="btn btn-outline-primary">Get Add-On</button></div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                    <div className='planadd'>
                        <div style={{ minHeight: "100px" }}>
                            <h4>Extra message credits</h4>
                            <span>$7 per 1000 messages / month</span>
                        </div>
                        <div className='d-flex justify-content-between align-items-center pt-4'>
                            <div>
                                <h4><strong>$7</strong></h4>
                                <span>Total per month</span>
                            </div>
                            <div className=''><button type="button" onClick={addon} class="btn btn-outline-primary">Get Add-On</button></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4'>
                    <div className='planadd'>
                        <div style={{ minHeight: "100px" }}>
                            <h4>Extra chatbots</h4>
                            <span>$10 per chatbot / month</span>
                        </div>
                        <div className='d-flex justify-content-between align-items-center pt-4'>
                            <div>
                                <h4><strong>$10</strong></h4>
                                <span>Total per month</span>
                            </div>
                            <div className=''><button type="button" onClick={addon} class="btn btn-outline-primary">Get Add-On</button></div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4'>
                    <div className='planadd'>
                        <div style={{ minHeight: "100px" }}>
                            <h4>Custom Domains</h4>
                            <span>Use your own custom domains for the embed script, iframe, and chatbot link</span>
                        </div>
                        <div className='d-flex justify-content-between align-items-center pt-4'>
                            <div>
                                <h4><strong>$59</strong></h4>
                                <span>Total per month</span>
                            </div>
                            <div className=''><button type="button" onClick={addon} class="btn btn-outline-primary">Get Add-On</button></div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4'>
                    <div className='planadd'>
                        <div style={{ minHeight: "100px" }}>
                            <h4>Remove 'Powered By Chatbase'</h4>
                            <span>Remove the Chatbase branding from the iframe and widget</span>
                        </div>
                        <div className='d-flex justify-content-between align-items-center pt-4'>
                            <div>
                                <h4><strong>$49</strong></h4>
                                <span>Total per month</span>
                            </div>
                            <div className=''><button type="button" onClick={addon} class="btn btn-outline-primary">Get Add-On</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PlanAdd;