import '../../Admin/style.css';
import React, { useState, useEffect } from 'react';
import Navbar from '../Sidebars/Navbar.jsx';
import Header from '../Sidebars/header.jsx';
import Home from './home';

function Layout() {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);

  const handleResize = () => {
    setIsNavbarVisible(window.innerWidth >= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleNavbar = () => {
    setIsNavbarVisible(prevState => !prevState);
  };

  return (
    <div className="container-fluid p-0">
      <Header toggleNavbar={toggleNavbar} isNavbarVisible={isNavbarVisible} />
      <div className="row">
        <div 
          className={`sizefix col-md-0 col-lg-3 col-xl-2 p-0 ${isNavbarVisible ? '' : 'd-none'}`} 
          style={{ borderRight: "1px solid #ebebeb" }}
        >
          <Navbar />
        </div>
        <div className="col-md-12 col-lg-9 col-xl-10 p-0">
          <Home />
        </div>
      </div>
    </div>
  );
}

export default Layout;
