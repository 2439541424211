import '../App.css';
import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import UserApiClient from '../ApiClients';
import Swal from 'sweetalert2';
import validatePassword from '../Configs/passValidation';

function ResetForgetPass(props) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const uidb64 = query.get('uidb64');
  const token = query.get('token');
  if (!uidb64 || !token) {
    Swal.fire("Somthing get wrong.", '', 'error', 3000);

  }

  const navigate = useNavigate();
  const [formData, setFormData] = useState({})
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name == "password") {
      const validationError = validatePassword(value);
      setError(validationError);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (error.length > 0) return;
    await UserApiClient.post(`api/reset-password/${uidb64}/${token}/`, formData).then(async res => {
      if (res.success) {
        Swal.fire(res.data.message, '', 'success', 3000);
        navigate('/login');
      }
    })
  };

  return (
    <>
      <div className="row user-login">
        <div className='col-sm-0 col-md-3 col-lg-5 col-xl-6 logo-set'>
          <div className='backarrow'><i className="fa-solid fa-circle-arrow-left my-4 mx-5" onClick={() => navigate('/')} style={{ fontSize: '24px', color: "#47a4e5", cursor: "pointer" }}></i></div>
          <div className='logo-img'><img src="/assets/Layer_1.png" className="logo" onClick={() => navigate('/')} alt="logo" style={{ width: "50%", cursor: "pointer" }} /></div>
        </div>
        <div className='col-sm-12 col-md-9 col-lg-7 col-xl-6 login-form'>
          <div className='forgetform'>
            <div className='signin'>
              <h4>Forget Password?</h4>
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <small id="passwordHelp" className="form-text text-muted">No worries! Just enter you new password.</small>
                  <input type="text" maxLength={100} className="form-control" placeholder="New Password*" name='password' onChange={handleChange} required />
                  {error && <div style={{ color: 'red' }}>{error}</div>}
                </div>

                <button type="submit" className="btn btn-primary signin-btn" >Submit</button>
                <div className='mt-4' style={{ textAlign: 'center' }}><h6>Just Remember? <NavLink to='/login'> Sign In</NavLink></h6></div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default ResetForgetPass;
