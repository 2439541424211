import '../../Admin/style.css';
import React, { useState, useEffect } from 'react';
import Navbar from '../Sidebars/Navbar.jsx';
import Header from '../Sidebars/header.jsx';
import UserApiClient from '../../ApiClients/index.jsx';
import { useNavigate } from 'react-router-dom';

function AdminDashboard() {
    
    const navigate = useNavigate();
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [isMobCheck, setIsMobCheck] = useState();
    const [data, setData] = useState({
        "users": 0,
        "revenue": 0.0
    });
    let isFetching = false;

    const handleResize = () => {
        setIsNavbarVisible(window.innerWidth >= 768);
    };

    async function fetchData() {
        isFetching = true;
        UserApiClient.get(`api/admin/dashboard/`, {}, true).then(res => {
            if (res.success) {
                setData({...data, ...res.data});
            }
            isFetching = false;
        })
    }
    useEffect(() => {
        if (!isFetching) {
            fetchData()
        }
    }, [])
    useEffect(() => {
        setIsMobCheck(window.innerWidth >= 768);
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleNavbar = () => {
        setIsNavbarVisible(prevState => !prevState);
    };


    return (
        <>
            <div className="container-fluid p-0">
                <Header toggleNavbar={toggleNavbar} isNavbarVisible={isNavbarVisible} />
                <div className="row">
                    <div
                        className={`sizefix col-md-0 col-lg-3 col-xl-2 p-0 ${isNavbarVisible ? '' : 'd-none'}`}
                        style={{ borderRight: "1px solid #ebebeb" }}
                    >
                        <Navbar />
                    </div>
                    <div className={`${isMobCheck ? (isNavbarVisible ? 'col-10 p-0' : 'col-12 p-0') : (isNavbarVisible ? 'd-none' : 'col-12 p-0')}`}>
                        <div className="main-sec mt-5">
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='p-3 outer' onClick={()=>navigate("/all-user")} style={{cursor: "pointer"}}>
                                        <div className='d-flex justify-content-between'>
                                            <span className="dash-widget-icon text-success">
                                                <i className="fa-regular fa-credit-card"></i>
                                            </span>
                                            <h3>{data.users}</h3>
                                        </div>
                                        <h6 className='my-3'>User</h6>
                                        <div className="progress" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                            <div className="progress-bar bg-success" style={{ width: "25%" }}></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-6'>
                                    <div className='p-3 outer' onClick={()=>navigate("/invoice")} style={{cursor: "pointer"}}>
                                    <div className='d-flex justify-content-between'>
                                        <span className="dash-widget-icon text-warning border-warning">
                                            <i className="fa-regular fa-folder"></i>
                                        </span>
                                        <h3>$ {data.revenue}</h3>
                                        </div>
                                        <h6 className='my-3'>Revenue</h6>
                                        <div className="progress" role="progressbar" aria-label="Warning example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                            <div className="progress-bar bg-warning" style={{ width: "75%" }}></div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default AdminDashboard;
