import "../../style.css"
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Navbar from '../../Sidebars/Navbar.jsx';
import Header from '../../Sidebars/header.jsx';
import Account from './Account.jsx';
import Plans from './Plans.jsx';
import Security from './Security.jsx';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function AdminSetting() {
    const [value, setValue] = useState(0);
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [isMobCheck, setIsMobCheck] = useState();


    const handleResize = () => {
        setIsNavbarVisible(window.innerWidth >= 768);
    };

    useEffect(() => {
        setIsMobCheck(window.innerWidth >= 768);
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleNavbar = () => {
        setIsNavbarVisible(prevState => !prevState);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className="container-fluid p-0">
                <Header toggleNavbar={toggleNavbar} isNavbarVisible={isNavbarVisible} />
                <div className="row">
                    <div
                        className={`sizefix col-md-0 col-lg-3 col-xl-2 p-0 ${isNavbarVisible ? '' : 'd-none'}`}
                        style={{ borderRight: "1px solid #ebebeb" }}
                    >
                        <Navbar />
                    </div>
                    <div className={`${isMobCheck ? (isNavbarVisible ? 'col-10 p-0' : 'col-12 p-0') : (isNavbarVisible ? 'd-none' : 'col-12 p-0')}`}>


                        <div className="main-sec mt-5">
                            <div className='row'>
                                <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 mainswitch'>
                                    <Box sx={{ border: '1px ridge', backgroundColor: '#ffffff', padding: '8px', borderRadius: '50px' }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
                                            <Tab label="Account" {...a11yProps(0)} />
                                            <Tab label="Security" {...a11yProps(1)} />
                                            <Tab label="Plans" {...a11yProps(2)} />
                                        </Tabs>
                                    </Box>
                                </div>
                                <div className='col-6'>
                                </div>
                            </div>
                            <CustomTabPanel value={value} index={0}>
                                <Account />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <Security />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <Plans />
                            </CustomTabPanel>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminSetting;
