import '../../../Admin/style.css';
import React, { useState, useEffect } from 'react';
import Navbar from '../../Sidebars/Navbar.jsx';
import Header from '../../Sidebars/header.jsx';
import { NavLink, useNavigate } from 'react-router-dom';
import UserApiClient from '../../../ApiClients/index.jsx';
import * as XLSX from 'xlsx';

function Invoice() {
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [isMobCheck, setIsMobCheck] = useState();
    
    const [revenueData, setRevenueData] = useState({
        "totalAmount": "",
        "totalUsers": "",
        "totalUnPaid": "",
        "totalPaid": "",
        "lastItemDate": "",
    });
    const [invoiceItems, setInvoiceItems] = useState([]);
    let isFetching = false;
    const navigate = useNavigate();

    const handleResize = () => {
        setIsNavbarVisible(window.innerWidth >= 768);
    };

    useEffect(() => {
        setIsMobCheck(window.innerWidth >= 768);
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleNavbar = () => {
        setIsNavbarVisible(prevState => !prevState);
    };

    const handleExport = () => {
        // Step 1: Format the data
        const formattedData = invoiceItems.map(item => ({
            id: item.id,
            username: item.user.username,
            first_name: item.user.first_name,
            last_name: item.user.last_name,
            email: item.user.email,
            subscription_type: item.subscription_type,
            amount: item.amount,
            created_at: item.created_at,
        }));

        // Step 2: Create a new workbook and worksheet
        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Subscriptions');

        // Step 3: Export the workbook to an Excel file
        XLSX.writeFile(workbook, 'Invoice-data-.xlsx');
    };


    // Your API call to fetch data
    const fetchData = async (useLoader) => {
        isFetching = true
        try {
            const res = await UserApiClient.get(`api/invoices/?all=True`, {}, true, undefined, useLoader);
            if (res.success) {
                setInvoiceItems(res.data.data);
                setRevenueData(res.data.revenueData)
            }
            isFetching = false
        } catch (error) {
            console.log("Error fetching user data:", error);
        }
    };

    async function handleSourceSearch(e) {
        e.preventDefault();
        const searchTerm = e.target.searchTerm.value;

        // Reset the documents based on the current value
        if (searchTerm.trim() === "") {
            // If searchTerm is empty, show original data
            await fetchData(false);
            return; // Exit the function early
        }

        // // Filter based on the searchTerm
        const filteredData = invoiceItems.filter(item =>
            item.user?.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setInvoiceItems(filteredData);
    }

    useEffect(() => {
        if (!isFetching) {
            fetchData()
        }

    }, []);

    return (
        <>
            <div className="container-fluid p-0">
                <Header toggleNavbar={toggleNavbar} isNavbarVisible={isNavbarVisible} />
                <div className="row">
                    <div
                        className={`col-md-2 col-lg-2 col-xl-2 p-0 ${isNavbarVisible ? '' : 'd-none'}`}
                        style={{ borderRight: "1px solid #ebebeb" }}
                    >
                        <Navbar />
                    </div>
                    <div className={`sizefix ${isMobCheck ? (isNavbarVisible ? 'col-10 p-0' : 'col-12 p-0') : (isNavbarVisible ? 'd-none' : 'col-12 p-0')}`}>

                        <div className="main-sec mt-5">
                            <div className='row mb-5'>
                                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                    <div className='invoice-container'>
                                        <p>Total Subscription Amount</p>
                                        <h2>$ {revenueData.totalAmount}</h2>
                                        <span>{revenueData.lastItemDate}</span>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                    <div className='invoice-containers'>
                                        <div className='invoice-blue'>
                                            <div className='icn-box'><i className="fa-regular fa-bookmark fa-xl"></i></div>
                                            <div>
                                                <p>Total Users</p>
                                                <h2>{revenueData.totalUsers}</h2>
                                            </div>
                                        </div>
                                        <div className='invoice-green'>
                                            <div className='icn-box'><i className="fa-regular fa-paper-plane fa-xl"></i></div>
                                            <div>
                                                <p>Paid Invoices</p>
                                                <h2>{revenueData.totalPaid}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='invoice-containers'>
                                        <div className='invoice-purple'>
                                            <div className='icn-box'><i className="fa-solid fa-angles-right fa-xl"></i></div>
                                            <div>
                                                <p>Invoice</p>
                                                <h2>{invoiceItems.length}</h2>
                                            </div>
                                        </div>
                                        <div className='invoice-red'>
                                            <div className='icn-box'><i className="fa-regular fa-circle-xmark fa-xl"></i></div>
                                            <div>
                                                <p>Unpaid Invoices</p>
                                                <h2>{revenueData.totalUnPaid}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='' style={{ display: 'flex', alignItems: "center" }}>
                                <div className='col-sm-4 col-md-5 col-lg-6 col-xl-6 mb-2' >
                                    <form className='searchbartab' onSubmit={handleSourceSearch}>
                                        <input type="text" placeholder="Search by email" name="searchTerm" className='m-0 rounded' style={{ width: "50%" }} />
                                        <button type="submit" className='border rounded'><i className="fa fa-search"></i></button>
                                    </form>
                                </div>
                                <div className='col-sm-8 col-md-7 col-lg-6 col-xl-6 selectbtns'>
                                    <button className='btn btn-primary px-4 py-2' onClick={handleExport}>Download</button>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="table-responsive">
                                        <table className="table category-tab text-start">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Invoice</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Plan</th>
                                                    <th scope="col">Cost</th>
                                                    {/* {/* <th scope="col">Download</th> */}
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoiceItems.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>#NB0{item.id}</td>
                                                        <td><NavLink className="nav-link" to={`/user-profile/${item.user?.id}`}>{item.user?.first_name} {item.user?.last_name}</NavLink></td>
                                                        <td><NavLink className="nav-link" to={`/user-profile/${item.user?.id}`}>{item.user?.email}</NavLink></td>
                                                        <td>{item.created_at}</td>
                                                        <td>{item.user?.plan?.subscription_config?.plan}</td>
                                                        <td>{item.amount}</td>
                                                        {/* {/* <td>
                                                            <span style={{ backgroundColor: '#4ea4e2', borderRadius: '20px', padding: '5px 20px', color: '#fff' }}>
                                                                <i className="fa-regular fa-file"></i>
                                                            </span>
                                                        </td> */}
                                                        <td className='text-capitalize'>{item.invoice_status}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Invoice;
