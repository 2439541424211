import Swal from "sweetalert2";
import UserApiClient from "../../../ApiClients";
import "../../style.css"
import React, { useState } from 'react';
import validatePassword from "../../../Configs/passValidation";


function Security() {

    const [data, setData] = useState({});
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name == "new_password") {
            const validationError = validatePassword(value);
            setError(validationError);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (data.new_password != data.confirm_password) {
            Swal.fire('New and Confirm password should be same.', '', 'warning', 3000)
            return;
        }

        if (error.length > 0) return;

        await UserApiClient.put(`api/change-password/`, { ...data }, true).then(async res => {
            if (res.success) {
                Swal.fire(res.data.message, '', 'success', 3000)
            }
        })
    }
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='account-setting'>
                        <h5>Change Password</h5>
                        <form className='my-5' onSubmit={handleFormSubmit}>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label htmlFor="old_password">Current Password</label>
                                    <input type="text" id="old_password" name='old_password' onChange={handleChange} required className="form-control" placeholder="Current Password" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label htmlFor="new_password">New Password</label>
                                    <input type="text" id="new_password" name='new_password' onChange={handleChange} required className="form-control" placeholder="New Password" />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label htmlFor="confirm_password">Confirm New Password</label>
                                    <input type="text" id="confirm_password" name='confirm_password' onChange={handleChange} required className="form-control" placeholder="ReEnter New Password" />
                                </div>
                                {error && <div style={{ color: 'red', marginBottom: '20px' }}>{error}</div>}
                            </div>
                            <h5>Password Requirements:</h5>
                            <span> - Minimum 8 Characters long - the more, the better</span> <br />
                            <span> - At least one lowercase Character</span> <br />
                            <span> - At least one uppercase Character</span> <br />
                            <span> - At least one number, symbol or whitespace Character</span> <br />
                            <div className="row mt-5">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <button type="submit" className="btn btn-primary" style={{ width: "100%" }}>Save Changes</button>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <button type="reset" className="btn btn-secondary" style={{ width: "100%" }}>Reset</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
            </div>

        </>
    );
}

export default Security;
