import React, { useState, useEffect, createContext } from 'react';
import UserApiClient from './ApiClients';

// Create a context
const UserContext = createContext();


// Create a provider component
const UserProvider = ({ children }) => {

  const [User, setUser] = useState({});
  const [TeamList, setTeamList] = useState([]);
  const [Team, setTeam] = useState({});
  const [Member, setMember] = useState({});
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');
  let isFetching = false;
  let isFetchingTeam = false;

  // Your API call to fetch user data
  const fetchData = async () => {
    isFetching = true
    try {
      const res = await UserApiClient.get(`api/user/${userId}/`, {}, true);
      if (res.success) {
        setUser(res.data);
      }
      isFetching = false
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  // Your API call to fetch user data
  const fetchTeamData = async () => {
    isFetchingTeam = true;
    try {
      const res = await UserApiClient.get(`api/team/list/${User.id}/`, {}, true);
      if (res.success) {
        setTeamList(res.data);
      }
      isFetchingTeam = false;
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (userId && !User.id && !!token && !isFetching) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (User.id && !isFetchingTeam) {
      fetchTeamData();
    }
  }, [User]);

  useEffect(() => {
    if (TeamList[0]) {
      setTeam(TeamList[0]);
    }
  }, [TeamList]);

  useEffect(() => {
    if (Team.member) {
      setMember(Team.member);
    }
  }, [Team]);

  return (
    // Wrap your component tree with the context provider
    <UserContext.Provider value={{ User, setUser, Member, setMember, Team, setTeam, TeamList, setTeamList }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };