import '../../Admin/style.css';
import React from 'react';
import Navbar from '../Sidebars/Navbar.jsx';
import Header from '../Sidebars/header.jsx';
import { useNavigate } from 'react-router-dom';

function BlogCategory() {

    const navigate = useNavigate();
    const categorylist = () => {
        navigate('/category-list');
      };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-2 p-0" style={{ borderRight: "1px solid #ebebeb" }}>
                        <Navbar />
                    </div>
                    <div className="col-10 p-0">
                        <Header />
                        <div className="main-sec">
                            <div className='serchblog p-4' >
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <h5 className='m-0' style={{ width: "100%" }}>Category List</h5>
                                    <input className="form-control me-2" type="search" placeholder="Search Category" aria-label="Search"></input></div>
                                <button onClick={categorylist} className='btn btn-primary px-4 py-2'>Add Category</button>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <table className="table category-tab">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">ID</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Discription</th>
                                                <th scope="col">Published</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td><label className="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label></td>
                                                <td>
                                                    <i className="fa fa-eye" aria-hidden="true"></i><i className="fa-solid fa-pen-to-square mx-4"></i><i className="fa-solid fa-trash-can mx-2"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>Jacob</td>
                                                <td>Thornton</td>
                                                <td><label className="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label></td>
                                                <td>
                                                    <i className="fa fa-eye" aria-hidden="true"></i><i className="fa-solid fa-pen-to-square mx-4"></i><i className="fa-solid fa-trash-can mx-2"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>Larry</td>
                                                <td>the Bird</td>
                                                <td><label className="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label></td>
                                                <td>
                                                    <i className="fa fa-eye" aria-hidden="true"></i><i className="fa-solid fa-pen-to-square mx-4"></i><i className="fa-solid fa-trash-can mx-2"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default BlogCategory;
