const loader = (loading) => {
  const outerLoader = document.getElementById('outerLoader');

  if (loading) {
    outerLoader.classList.remove('d-none');
  } else {
    outerLoader.classList.add('d-none');
  }
};

export default loader;
