import '../App.css';
import React, { useState, useContext } from 'react';
import { NavLink } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../TabContextProvider';
import { useLocation } from 'react-router-dom';

function OpenHeader() {
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const token = localStorage.getItem('token');
    const { User } = useContext(UserContext);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const navigate = useNavigate();
    const logPage = () => {
        navigate('/login');
    }
    const SignPage = () => {
        navigate('/signup');
    }
    const layoutPage = () => {
        if (User?.is_superuser) {
            navigate('/all-user');
        } else if (!User?.is_superuser) {
            navigate(`/dashboard`);
        }
    }
    const logoutPage = () => {
        localStorage.removeItem('hasVisited');
        localStorage.removeItem('login');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('token');
        localStorage.removeItem('u_id');
        localStorage.removeItem('userId');
    
        // Navigate to the login page
        navigate('/login');
    }
    const isHomePage = location.pathname === '/';

    const toggleDropdown = () => {
        setIsOpen(prev => !prev);
    }
    return (

        <>
            {/* Header */}
            <div className='topbar'>
                <div class="row m-0">
                {isHomePage ? (
                    <div className="col-12 tapbar-text">
                        <div className="header-top-text">
                            <p className='m-0'>New members: get your first 7 days of turitor Premium for free! Unlock discount now!</p>
                        </div>
                        <div className="header-top-social">
                            <ul>
                                <li><a href="https://x.com/chat_zippy"><i className="fa-brands fa-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/in/chatzippy/"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                <li><a href="https://www.youtube.com/@ChatZippy"><i className="fab fa-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>
                ) : null}
                    
                </div>
            </div>
            <div className="row maindash-nav py-2 w-100">
                <div className='col-sm-6 col-md-3 col-lg-3 col-xl-3'>
                    <div className=''>
                        <img src="/assets/Layer_1.png" className="logo" alt="logo" onClick={() => navigate(`/`)} style={{ width: '40%', cursor: "pointer" }} />
                    </div>
                </div>
                <div className='col-sm-0 col-md-6 col-lg-6 col-xl-6'>
                    <div className='dash-nav'>
                        <ul style={{ margin: '0px' }}>
                            <li>
                                <NavLink className="nav-link " to="/">Home</NavLink>
                            </li>
                            <li>
                                <a href='/#features' className="nav-link ">Features </a>
                            </li>
                            <li>
                                <a href='/#price' className="nav-link ">Price </a>
                            </li>
                            <li>
                                <NavLink to='/Contact-Us' className="nav-link ">Contact </NavLink>
                            </li>
                            <li>
                                <div className="dropdown" style={{ display: "flex" }}>
                                    <button 
                                        className="btn dropdown-toggle pe-0 text-capitalize" 
                                        type="button" 
                                        onClick={toggleDropdown}
                                        aria-expanded={isOpen}
                                    >
                                        Resources
                                    </button>
                                    {isOpen && (
                                        <ul className="dropdown-menu show" aria-labelledby="dropdownMenuButton">
                                            <li><NavLink className="dropdown-item" to={"/Guide"}>Guide</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/blog">Blog</NavLink></li>
                                        </ul>
                                    )}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-sm-6 col-md-3 col-lg-3 col-xl-3 text-end'>
                    <div className='dashlogin'>
                        {!User.id || !token ? (
                            <>
                                <button type="button" onClick={logPage} style={{ border: "none",borderRadius:"50px" }} className="btn btn-outline-primary py-2 px-4 me-2"><i class="fa-regular fa-user mr-2"></i>Login</button>
                                <button type="button" onClick={SignPage} style={{borderRadius:"50px"}} className="btn btn-primary py-2 px-4">Join AI</button>
                            </>
                        ) : (
                            <>
                            <button type="button" onClick={layoutPage} style={{borderRadius:"50px"}} className="btn btn-primary py-2 px-4 mr-2">Chatbot</button>
                            <button type="button" onClick={logoutPage} style={{borderRadius:"50px"}} className="btn btn-primary py-2 px-4">Logout</button>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="row maindashmob-nav">
                <div className='col-6'>
                    <div>
                        <img
                            src="/assets/Layer_1.png"
                            className="logo"
                            alt="logo"
                            onClick={() => navigate(`/`)}
                            style={{ width: '80%', cursor: "pointer" }}
                        />
                    </div>
                </div>
                <div className='col-6 d-flex justify-content-end align-items-center'>
                    <button
                        className="btn btn-primary"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <i class="fa-solid fa-bars"></i>
                    </button>
                </div>
            </div>

            {/* Sidebar Component */}
            {sidebarOpen && (
                <div className="sidebar">
                    <button className="close-btn" onClick={() => setSidebarOpen(false)}>Close</button>
                    <ul className="sidebar-menu">
                        <li><NavLink className="nav-link " to="/">Home</NavLink></li>
                        <li> <a href='/#features' className="nav-link ">Features </a></li>
                        <li><a href='/#price' className="nav-link ">Price </a></li>
                        <li><NavLink to='/Contact-Us' className="nav-link ">Contact </NavLink></li>
                        <li>{!User.id || !token ? (
                            <>
                                <button type="button" onClick={logPage} style={{ border: "none" }} className="btn btn-primary w-100 mb-2">Sign In</button>
                                <button type="button" onClick={SignPage} className="btn btn-primary w-100">Try for free<i className="fa-solid fa-arrow-right-long ms-2"></i></button>
                            </>
                        ) : (
                            <button type="button" onClick={layoutPage} className="btn btn-primary">Dashboard</button>
                        )}</li>
                        {/* Add more menu items as needed */}
                    </ul>
                </div>
            )}
            {/* <div className='dash-mob'>
                <div>
                    {Object.keys(User).length == 0 ? (
                        <>
                            <img src="/assets/logo4.png" className="logo dash-logo" alt="logo" />
                            <div>
                                <button type="button" onClick={logPage} style={{ border: "none" }} className="btn btn-outline-primary me-2">Login</button>
                                <button type="button" onClick={layoutPage} className="btn btn-primary">Sign Up</button>
                            </div>
                        </>
                    ) : (
                        <button type="button" onClick={SignPage} className="btn btn-primary">Dashboard</button>
                    )}
                </div>
            </div> */}
            {/* Header */}
        </>
    );
}

export default OpenHeader;