import '../../../App.css';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../TabContextProvider';
import UserApiClient from '../../../ApiClients';
import Swal from 'sweetalert2';

function Teams() {

    const { User, Team, setTeam } = useContext(UserContext);
    const [userTeam, setUserTeam] = useState({});
    const [members, setMembers] = useState([]);
    let isFetching = false;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserTeam((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const isValidSlug = (slug) => {
        const pattern = /^[a-z0-9]+(?:-[a-z0-9]+)*$/; // Regex pattern for valid slug
        return pattern.test(slug);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!isValidSlug(userTeam.slug)) {
            Swal.fire("Please enter a valid url.", '', 'error', 3000);
            return;
        }
        UserApiClient.post(`api/team/get_or_create/${User.id}/`, { ...userTeam }, true).then(async res => {
            if (res.success) {
                await fetchData(true);
                Swal.fire(res.data.message, '', 'success', 3000);

            }
        })
    }

    async function fetchData(update_provider) {
        isFetching = true;
        UserApiClient.get(`api/team/get_or_create/${User.id}/?team_id=${Team.id}`, {}, true).then(res => {
            if (res.success && res.data.team) {
                setUserTeam(res.data.team);
                setMembers(res.data.members);
                if (update_provider) setTeam(res.data.team);
            }
            isFetching = false;
        })
    }

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const handleInviteMember = () => {
        Swal.fire({
            title: "Invite Member",
            html: `
            <input id="email" type="email" class="form-control mx-auto mb-2 w-100" placeholder="Enter Member's Email">
            <select class="form-control mx-auto mb-2 w-100" id="role">
            <option value="">Select Role</option>
            <option value="member">Member</option>
            <option value="owner">Owner</option>
            </select>
            `,
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Send Invitation",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const email = Swal.getPopup().querySelector('#email').value;
                const role = Swal.getPopup().querySelector('#role').value;
                // Validate the fields
                if (!email || !validateEmail(email)) {
                    Swal.showValidationMessage(`Email fields is required`);
                    return;
                }
                if (!role) {
                    Swal.showValidationMessage(`Role fields is required`);
                    return;
                }

                // Create FormData object to handle file uploads
                const formData = new FormData();
                formData.append('email', email);
                formData.append('role', role);
                formData.append('team', Team.id);

                const res = await UserApiClient.post(`api/member/invite/${User.id}/`, formData, true, undefined, false);
                if (res.success) {
                    const data = await res.data;
                    return { data };
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed && result.value.data) {
                Swal.fire({
                    title: result.value.data.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        });
    };

    const handleMemberRemove = async (member_id) => {
        await Swal.fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Remove!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await UserApiClient.delete(`api/member/remove/${member_id}/`, {}, true).then(async res => {
                    if (res.success) {
                        await fetchData();
                        Swal.fire(res.data.message, '', 'success', 3000);
                    }
                })
            }
        });
    }

    const handleDeleteTeam = async (e) => {
        e.preventDefault();
        await Swal.fire({
            // title: "Delete Team",
            html: `
            <div class="d-flex flex-column text-center text-sm-start mb-2">
                <h2 class="h5 fw-semibold text-danger d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="bi bi-exclamation-triangle me-2" style="height: 2rem; width: 2rem;">
                        <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3"></path>
                        <path d="M12 9v4"></path>
                        <path d="M12 17h.01"></path>
                    </svg>
                    Delete ${Team.name} (${Team.slug})?
                </h2>
                <p class="small text-muted">
                    This will delete all the data associated with your team. And remove all members related to team.<br>
                    <strong>This action cannot be undone.</strong>
                </p>
                <label class="form-label small fw-medium" for="name">Team Name</label>
                <input id="team_name" type="team_name" class="form-control mx-auto mb-2 w-100" placeholder="${Team.name}">
                <p class="small text-muted">Please type your team name to confirm.</p>
            </div>
            `,
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Delete Team",
            confirmButtonColor: "#3085d6",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const team_name = Swal.getPopup().querySelector('#team_name').value;
                // Validate the fields
                if (!team_name || team_name != Team.name) {
                    Swal.showValidationMessage(`Please fill the correct team name.`);
                    return;
                }

                const res = await UserApiClient.delete(`api/team/delete/${Team.id}/`, {}, true, undefined, false)
                if (res.success) {
                    const data = await res.data;
                    return { data };
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed && result.value.data) {
                Swal.fire({
                    title: result.value.data.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000
                });
                setTimeout(()=>{window.location.href = '/dashboard/'}, 2000);
            }
        });
    }

    useEffect(() => {
        if (User.id && !isFetching && Team.id) {
            fetchData();
        }
    }, [User, Team])

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='account-setting'>
                        <div className='billingContainer'>
                            <div className=''>
                                <h4><strong>General</strong></h4>
                                <form onSubmit={handleFormSubmit}>
                                    <div className="form-group interface">
                                        <label htmlFor="name">Team Name</label>
                                        <input type="text" className="form-control"
                                            name="name"
                                            id="name"
                                            onChange={handleChange}
                                            value={userTeam.name}
                                            maxLength={200}
                                            placeholder={`${User.first_name}'s team`}
                                            required
                                        />
                                    </div>
                                    <div className="form-group interface">
                                        <label htmlFor="slug">Team URL</label>
                                        <input type="text" className="form-control"
                                            name="slug"
                                            id="slug"
                                            onChange={handleChange}
                                            value={userTeam.slug}
                                            maxLength={200}
                                            placeholder={`${User.first_name}-team-2b549a7e`}
                                            required
                                        />
                                        <small className="form-text text-muted">Updating Team URL will cause a redirect to the new url.</small>
                                    </div>
                                    <div className='d-flex justify-content-end'> <button type="submit" className="btn btn-primary px-5">Save</button></div>
                                </form>
                                <div className='billingtable mt-4'>
                                    <label className='my-2' htmlFor="firstName">Members</label>
                                    <div className='col-12 p-0'>
                                        <div className="table-responsive">
                                            <table className="table category-tab text-start">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Sr.</th>
                                                        <th scope="col">User</th>
                                                        <th scope="col">Member since</th>
                                                        <th scope="col">Role</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {members.map((member, index) => (
                                                        <tr key={member.id}>
                                                            <td>#{index + 1}</td>
                                                            <td>{member.email}</td>
                                                            <td>{member.created_at}</td>
                                                            <td className='text-capitalize'>{member.role}</td>
                                                            <td>
                                                                <button className='icn-btn' onClick={() => handleMemberRemove(member.id)}>
                                                                    <i className="fa-solid fa-trash-can mx-2" style={{ color: "red" }}></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className='d-flex justify-content-end'> <button type="button" className="btn btn-primary" onClick={handleInviteMember}>Invite members</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleDeleteTeam}>
                    <div className='billingContainer mt-3'>
                        <h5><strong>Delete Team</strong></h5>
                        <div className=''>
                            <p>Once you delete your team account, there is no going back. Please be certain.<br /> All your uploaded data and trained chatbots will be deleted. This action is not reversible</p>
                        </div>
                        <div className="form-check my-4" >
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                I confirm my Team deactivation
                            </label>
                        </div>
                        <div className='d-flex justify-content-end'> <button type="submit" className="btn btn-danger px-4">Delete Team</button></div>
                    </div>
                </form>
            </div>

        </>
    );
}

export default Teams;
