import '../../../Admin/style.css';
import React, { useState, useEffect } from 'react';
import Navbar from '../../Sidebars/Navbar.jsx';
import Header from '../../Sidebars/header.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';

function FAQ() {
    const [showModal, setShowModal] = useState(false);
    const [editFAQ, setEditFAQ] = useState(null);
    const [isMobCheck, setIsMobCheck] = useState();
    const [isAllChecked, setIsAllChecked] = useState();
    const [faqs, setFaqs] = useState([]);
    const [checkedItems, setCheckedItems] = useState(faqs.map(() => false));
    const [newFAQ, setNewFAQ] = useState({ question: '', answer: '', page: 'Select', status: false });
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);

    const handleResize = () => {
        setIsNavbarVisible(window.innerWidth >= 768);
    };

    useEffect(() => {
        setIsMobCheck(window.innerWidth >= 768);
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleNavbar = () => {
        setIsNavbarVisible(prevState => !prevState);
    };


    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleSave = () => {
        if (editFAQ) {
            // Update existing FAQ
            setFaqs(prevFaqs => prevFaqs.map(faq =>
                faq.id === editFAQ.id ? { ...editFAQ, ...newFAQ } : faq
            ));
        } else {
            // Add new FAQ
            setFaqs(prevFaqs => [
                ...prevFaqs,
                {
                    id: prevFaqs.length + 1,
                    ...newFAQ
                }
            ]);
        }
        handleClose();
        setNewFAQ({ question: '', answer: '', page: 'Select', status: false });
        setEditFAQ(null);
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setNewFAQ(prev => ({ ...prev, [id]: value }));
    };

    const handleCheckboxChanges = (e) => {
        setNewFAQ(prev => ({ ...prev, status: e.target.checked }));
    };

    const DeleteFaq = (id) => {
        setFaqs(prevFaqs => prevFaqs.filter(faq => faq.id !== id));
    };

    const EditFaq = (id) => {
        const faqToEdit = faqs.find(faq => faq.id === id);
        setNewFAQ(faqToEdit);
        setEditFAQ(faqToEdit);
        handleShow();
    };

    const handleMainCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setIsAllChecked(isChecked);
        setCheckedItems(faqs.map(() => isChecked));
    };

    const handleCheckboxChange = (index) => {
        const updatedCheckedItems = [...checkedItems];
        updatedCheckedItems[index] = !updatedCheckedItems[index];
        setCheckedItems(updatedCheckedItems);
        // Update the main checkbox status based on individual checkboxes
        setIsAllChecked(updatedCheckedItems.every(Boolean));
    };

    return (
        <>
            <div className="container-fluid p-0">
                <Header toggleNavbar={toggleNavbar} isNavbarVisible={isNavbarVisible} />
                <div className="row">
                    <div
                        className={`sizefix col-md-0 col-lg-3 col-xl-2 p-0 ${isNavbarVisible ? '' : 'd-none'}`}
                        style={{ borderRight: "1px solid #ebebeb" }}
                    >
                        <Navbar />
                    </div>
                    <div className={`${isMobCheck ? (isNavbarVisible ? 'col-10 p-0' : 'col-12 p-0') : (isNavbarVisible ? 'd-none' : 'col-12 p-0')}`}>

                        <div className="main-sec mt-5">
                            <div className='serchblog p-4'>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <h5 className='m-0' style={{ width: "100%" }}>FAQs</h5>
                                    <input className="form-control me-2" type="search" placeholder="Search FAQs" aria-label="Search" />
                                </div>
                                <div>
                                    <button onClick={handleShow} className='btn btn-primary me-4'>Add New</button>
                                    <button type="button" className="btn btn-danger">Delete Selected</button>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="table-responsive">
                                        <table className="table category-tab">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col" style={{ paddingLeft: "30px" }}>
                                                        <input
                                                            className="form-check-input"
                                                            style={{ position: 'unset' }}
                                                            type="checkbox"
                                                            checked={isAllChecked}
                                                            onChange={handleMainCheckboxChange}
                                                        />
                                                    </th>
                                                    <th scope="col">Question</th>
                                                    <th scope="col">Answer</th>
                                                    <th scope="col">Page</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {faqs.map((faq, index) => (
                                                    <tr key={faq.id}>
                                                        <td style={{ paddingLeft: "30px" }}>
                                                            <input
                                                                className="form-check-input"
                                                                style={{ position: 'unset' }}
                                                                type="checkbox"
                                                                checked={checkedItems[index]}
                                                                onChange={() => handleCheckboxChange(index)}
                                                            />
                                                        </td>
                                                        <td>{faq.question}</td>
                                                        <td>{faq.answer}</td>
                                                        <td>{faq.page}</td>
                                                        <td>
                                                            <label className="switch">
                                                                <input type="checkbox" checked={faq.status} readOnly />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <button className='icn-btn'><i className="fa fa-eye" aria-hidden="true"></i></button>
                                                            <button className='icn-btn' onClick={() => EditFaq(faq.id)}><i className="fa-solid fa-pen-to-square mx-4"></i></button>
                                                            <button className='icn-btn' onClick={() => DeleteFaq(faq.id)}><i className="fa-solid fa-trash-can mx-2"></i></button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{editFAQ ? 'Edit FAQ' : 'Add FAQ'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-1">
                            <label htmlFor="question">Question</label>
                            <input
                                type="text"
                                className="form-control py-4 mt-0 mb-1"
                                id="question"
                                value={newFAQ.question}
                                onChange={handleInputChange}
                                placeholder="Question Here.."
                            />
                        </div>
                        <div className="form-group mb-1">
                            <label htmlFor="answer">Answer</label>
                            <input
                                type="text"
                                className="form-control py-4 mt-0 mb-1"
                                id="answer"
                                value={newFAQ.answer}
                                onChange={handleInputChange}
                                placeholder="Answer Here.."
                            />
                        </div>
                        <div className="form-group mb-1">
                            <label htmlFor="page">Select Page</label>
                            <select
                                id="page"
                                className="form-select form-select-md mb-3"
                                value={newFAQ.page}
                                onChange={handleInputChange}
                            >
                                <option value="Select">Select</option>
                                <option value="Home">Home</option>
                                <option value="About">About</option>
                                {/* Add more options as needed */}
                            </select>
                        </div>
                        <div className="form-group mb-1">
                            <label htmlFor="status" className='me-4'>Status</label>
                            <input
                                type="checkbox"
                                id="status"
                                checked={newFAQ.status}
                                onChange={handleCheckboxChanges}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        {editFAQ ? 'Update' : 'Save'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default FAQ;