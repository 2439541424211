import '../NewApp.css';
import React, { useState } from 'react';
import axios from 'axios';

function Footer() {
    const [email, setEmail] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('/contact_info/', {
                email,
                csrfmiddlewaretoken: document.querySelector('[name=csrfmiddlewaretoken]').value,
            });

            setResponseMessage(response.data.message);
            setIsError(false);
            setEmail('');

            setTimeout(() => {
                setResponseMessage('');
            }, 2000);
        } catch (error) {
            console.error(error); // Log error for debugging
            setResponseMessage('An error occurred. Please try again.');
            setIsError(true);

            setTimeout(() => {
                setResponseMessage('');
            }, 2000);
        }
    };

    return (
        <footer className="h5_footer-area">
            <div className="h5_footer-top pb-80">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-3 col-lg-3 col-md-5 col-sm-7 tp_has_fade_anim" data-fade-from="left">
                            <div className="h5_footer-widget mb-40">
                                <div className="h5_footer-logo">
                                    <a href="#"><img src="/assets/Layer_1.png" alt="Logo" /></a>
                                </div>
                                <p className="h5_footer-widget-text">Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                                <div className="h5_footer-widget-social">
                                    <a href="https://x.com/chat_zippy"><i className="fa-brands fa-twitter"></i></a>
                                    <a href="https://www.linkedin.com/in/chatzippy/"><i className="fa-brands fa-linkedin-in"></i></a>
                                    <a href="https://www.youtube.com/@ChatZippy"><i className="fa-brands fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 tp_has_fade_anim" data-fade-from="left" data-delay=".8">
                            <div className="h5_footer-widget mb-35">
                                <h5 className="h5_footer-widget-title">Company</h5>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/about/">About Us</a></li>
                                    <li><a href="/price/">Pricing</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 d-flex justify-content-lg-center justify-content-xl-start order-sm-3 order-md-2 tp_has_fade_anim" data-fade-from="left" data-delay="1.1">
                            <div className="h5_footer-widget mb-35">
                                <h5 className="h5_footer-widget-title">Use Cases</h5>
                                <ul>
                                    <li><a href="/terms-conditions/">For Teams</a></li>
                                    <li><a href="/blog/">For Blog Writers</a></li>
                                    <li><a href="/faq/">FAQ's</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-7 order-sm-2 order-md-3 tp_has_fade_anim" data-fade-from="left" data-delay="1.3">
                            <div className="h5_footer-widget mb-35">
                                <h5 className="h5_footer-widget-title">Contact Info</h5>
                                {responseMessage && (
                                    <p style={{ color: isError ? 'red' : 'green' }}>{responseMessage}</p>
                                )}
                                <form onSubmit={handleSubmit}>
                                    <input
                                        type="email"
                                        placeholder="Enter Your Email Address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <button type="submit">Subscribe</button>
                                </form>
                                <p className="h5_footer-widget-text">
                                    Subscribe to our newsletter for future updates. <br />
                                    Don’t worry, we won’t spam your email address.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h5_footer-bottom">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-md-6">
                            <div className="h5_footer-bottom-copyright d-flex justify-content-center justify-content-md-start">
                                <p>&copy; 2023 ChatZippy All Rights Reserved by site</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <div className="h5_footer-bottom-menu d-flex justify-content-center justify-content-md-end">
                                <ul>
                                    <li><a href="/privacy-policy/">Privacy Policy</a></li>
                                    <li><a href="/terms-conditions/">Term of Service</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
