const validatePassword = (password) => {
    const minLength = /.{8,}/; // Minimum 8 characters
    const hasLowercase = /[a-z]/; // At least one lowercase character
    const hasUpperCase = /[A-Z]/; // At least one uppercase character
    const hasSpecialChar = /[0-9!@#$%^&*(),.?":{}|<>]/; // At least one number, symbol, or whitespace character

    if (!minLength.test(password)) {
        return 'Password must be at least 8 characters long.';
    }
    if (!hasLowercase.test(password)) {
        return 'Password must contain at least one lowercase character.';
    }
    if (!hasUpperCase.test(password)) {
        return 'Password must contain at least one Uppercase character.';
    }
    if (!hasSpecialChar.test(password)) {
        return 'Password must contain at least one number, symbol, or whitespace character.';
    }
    return '';
};

export default validatePassword;