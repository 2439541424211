import '../../../Admin/style.css';
import React from 'react';
import Navbar from '../../Sidebars/Navbar.jsx';
import Header from '../../Sidebars/header.jsx';
import { useNavigate } from 'react-router-dom';

function Inactiveusers() {

    // const navigate = useNavigate();
    // const adduser = () => {
    //     navigate('/adduser');
    // };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-2 p-0" style={{ borderRight: "1px solid #ebebeb" }}>
                        <Navbar />
                    </div>
                    <div className="col-10 p-0">
                        <Header />
                        <div className="main-sec">
                            <div className='serchblog p-4' >
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <h5 className='m-0' style={{ width: "100%" }}>Inactive User List</h5>
                                    <input className="form-control me-2" type="search" placeholder="Search User" aria-label="Search"></input></div>
                                {/* <button onClick={adduser} className='btn btn-primary px-4 py-2'>Add User</button> */}
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <table className="table category-tab">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">User</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Generation</th>
                                                <th scope="col">Plan</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td><span style={{ backgroundColor: '#fdf3b9', borderRadius: '20px', padding: '5px 20px', border: '1px solid #fcd736' }}>Gold</span></td>
                                                <td><label className="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label></td>
                                                <td>
                                                    <i className="fa fa-eye" aria-hidden="true"></i><i className="fa-solid fa-pen-to-square mx-4"></i><i className="fa-solid fa-trash-can mx-2"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>Jacob</td>
                                                <td>Thornton</td>
                                                <td><span style={{ backgroundColor: '#efdac5', borderRadius: '20px', padding: '5px 20px', border: '1px solid brown' }}>Bronze</span></td>
                                                <td><label className="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label></td>
                                                <td>
                                                    <i className="fa fa-eye" aria-hidden="true"></i><i className="fa-solid fa-pen-to-square mx-4"></i><i className="fa-solid fa-trash-can mx-2"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Inactiveusers;
